footer{
    background-color: rgb(0, 0, 0);
    position: absolute;
    /*z-index: 9;*/
    width: 100%;
}
.text-white {
    text-decoration: none;
}
.footer-20192 {
    color: #fff;
    padding: 7rem 0px 0px 0rem;
}
.footer-20192 .container {
    position: relative; }
.footer-20192 h3 {
    color: #ffc866;
    font-weight: 700;
    font-size: larger;
    margin-bottom: 10px;
    margin-top: 0;
    line-height: 1.5;
    text-align: justify;
}

.footer-20192 .links li {
    margin-bottom: 10px;
    line-height: 1.5;
    display: block; }
.footer-20192 .links li a {
    color: #666873; }
.footer-20192 .links li a:hover {
    color: #fff; }
.footer-20192 .social li {
    display: inline-block;
    position: relative; }
.footer-20192 .social li a {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    color: #fff; }
.footer-20192 .social li a > span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
.footer-20192 .footer-logo {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: .1rem; }
.footer-20192 .copyright {
    color: #666873; }
.footer-20192 .cta {
    -webkit-box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
    box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
    padding: 20px;
    background-color: #ffffff;
    top: -150px;
    position: relative; }
.footer-20192 .cta h2, .footer-20192 .cta h3 {
    line-height: 1.5; }
.footer-20192 .cta h3 {
    font-size: 20px; }
.footer-20192 .links li a {
    color: #ffffff;
    text-decoration: none;
}
li.location-li {
    display: flex !important;
    width: 20rem;
}
ul.list-unstyled.links{
    text-align: left;
}
