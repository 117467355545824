.section-title h3 {
    color: black;
}
.contact-change6{
    height: 50px;
    width: max-content;
    background: #1c9d1c;
    margin: 20px;
    border:0px;
    padding: 2px 15px;
    border-radius: 10px;
    color:#fff;
    box-shadow: 0 0 1px #ccc;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-timing-function: linear;
    box-shadow:0px 0 0 #31708f  inset;
}
.contact-change6:hover{
    box-shadow: 0 0 0 25px #040404 inset;
    -webkit-transform: scale(1);
    color: white;

}
.contact-change6 a{
    color:#fff;
    text-decoration: none;
}
.contact-change6 a:hover{
    color:#fff;
    text-decoration: none;
}





/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
/*.row.align-items-center.features-item{*/
/*    margin-top: -95px;*/
/*}*/
/*.features .features-item+.features-item {*/
/*    margin-top: 100px;*/
/*}*/

@media (max-width: 640px) {
    .features .features-item+.features-item {
        margin-top: 40px;
    }
}

.features .features-item h3 {
    font-weight: 600;
    font-size: 26px;
    color: #001973;
}

.features .features-item ul {
    list-style: none;
    padding: 0;
}

.features .features-item ul li {
    padding-bottom: 10px;
}

.features .features-item ul li:last-child {
    padding-bottom: 0;
}

.features .features-item ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--color-primary);
}

.features .features-item p:last-child {
    margin-bottom: 0;
    text-align: left;
}


/*Accounting Tax*/
span.Pricing-button{
    position: relative;
    display: inline-flex;
    width : auto;
    height: 55px;
    margin: 0 15px;
    perspective: 1000px;
}
span.Pricing-button a{
    font-size: 19px;
    letter-spacing: 1px;
    transform-style: preserve-3d;
    transform: translateZ(-25px);
    transition: transform .25s;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;

}
span.Pricing-button a:before,
span.Pricing-button a:after{

    content: "\f2b9 View Pricing";
    height: 55px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid #1c9d1c;
    box-sizing: border-box;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;

}
span.Pricing-button a:before{
    color: #fff;
    background: #1c9d1c;
    transform: rotateY(0deg) translateZ(25px);
}
span.Pricing-button a:after{
    color: #000;
    transform: rotateX(90deg) translateZ(25px);
}
span.Pricing-button a:hover{
    transform: translateZ(-25px) rotateX(-90deg);
}






/*FCS CSS*/
ul.fsc-ul {
    color: black;
    text-align: left;
    list-style: disc !important;
}


/*KNOW YOUR TAX*/

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
}

.blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}

.blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
    color: #556270;
    transition: 0.3s;
}

.blog .entry .entry-title a:hover {
    color: #d9232d;
}

.blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #c1c8d0;
}

.blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.blog .entry .entry-meta ul li+li {
    padding-left: 20px;
}

.blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
}

.blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
}

.blog .entry .entry-content p {
    line-height: 24px;
}

.blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
}

.blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #d9232d;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
    background: #df3740;
}

.blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
}

.blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #556270;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
    color: #a4afba;
    display: inline;
}

.blog .entry .entry-footer a {
    color: #606f7e;
    transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
    color: #d9232d;
}

.blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}

.blog .entry .entry-footer .cats li {
    display: inline-block;
}

.blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}

.blog .entry .entry-footer .tags li {
    display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
}

.blog .entry .entry-footer .share {
    font-size: 16px;
}

.blog .entry .entry-footer .share i {
    padding-left: 5px;
}

.blog .entry-single {
    margin-bottom: 30px;
}

.blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
    width: 120px;
    margin-right: 20px;
}

.blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #556270;
}

.blog .blog-author .social-links {
    margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
    color: rgba(85, 98, 112, 0.5);
    margin-right: 5px;
}

.blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
}

.blog .blog-comments {
    margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
    font-weight: bold;
}

.blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
}

.blog .blog-comments .comment .comment-img {
    margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
    width: 60px;
}

.blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444444;
    transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
    color: #d9232d;
}

.blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #556270;
}

.blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
}

.blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #6b7b8d;
    margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
}

.blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
}

.blog .blog-comments .reply-form p {
    font-size: 14px;
}

.blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #e9797f;
}

.blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #e9797f;
}

.blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #556270;
}

.blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #606f7e;
}

.blog .blog-pagination {
    color: #8795a4;
}

.blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

.blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
}

.blog .blog-pagination li a {
    color: #556270;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
    background: #d9232d;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
    color: #fff;
}

.blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #556270;
    position: relative;
}

.blog .sidebar .sidebar-item {
    margin-bottom: 30px;
}

.blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
}

.blog .sidebar .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    /*width: calc(100% - 40px);*/
    width: -webkit-fill-available;
    margin-left: -10px;
}

.blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: #636161;
    color: #131212;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
}

.blog .sidebar .search-form form button i {
    line-height: 0;
}

.blog .sidebar .search-form form button:hover {
    background: #1c9d1c;
}

.blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

.blog .sidebar .categories ul li+li {
    padding-top: 10px;
}

.blog .sidebar .categories ul a {
    color: #556270;
    transition: 0.3s;
    text-decoration: none;
}

.blog .sidebar .categories ul a:hover {
    color: #064cce;
    text-decoration: underline;
}

.blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
}
.container.table-blog {
    width: 90%;
}


.blog .sidebar .recent-posts .post-item+.post-item {
    margin-top: 15px;
}

.blog .sidebar .recent-posts img {
    width: 80px;
    float: left;
}

.blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
    color: #556270;
    transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
    color: #d9232d;
}

.blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #aaaaaa;
}

.blog .sidebar .tags {
    margin-bottom: -10px;
}

.blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
}

.blog .sidebar .tags ul li {
    display: inline-block;
}

.blog .sidebar .tags ul a {
    color: #96a2af;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid white;
    display: inline-block;
    transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid #d9232d;
    background: #d9232d;
}

.blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: #fbfbfc;
    font-size: 14px;
}
tr{
    text-align: left;
}
thead {
    background-color: #1c9d1c;
    color: white;
}
li.nav-item.blog-tabslist {
    font-size: 16px;
    font-weight: 600;
    color: black !important;
}
li.nav-item.blog-tabslist .active {
    font-size: 16px;
    font-weight: 600;
    color: #1c9d1c;
    border-bottom: 3px solid #1c9d1c !important;
}
a.nav-link.blog-atags {
    color: black;
}
.nav-tabs .nav-link.active{
    border: none !important;
    border-bottom: 3px solid #1c9d1c !important;
}
.nav-tabs .nav-link:hover{
    border: none !important;

}



/*Income Tax Return Deadline*/

img.income-dd{
    width: 195%;
    height: auto;
}


.social-icons a:hover{
    color: white;
}
