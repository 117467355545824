body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #9a9a9a;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
header.header{
    position: fixed;
    z-index: 9999;
    background-color: white;
    width: 100%;
}
nav.navbar.navbar-expand-lg.navbar-light {
    margin: 1rem 0px;
}
.container.header{
    max-width: 1629px !important;
}
.button {
    border-radius: 50px;
    padding-top: 10px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-left: 40px;
    font-family: inherit;
    font-weight: inherit;
    border: 2px black solid;
    display: inline-block;
    background-color: #1c9d1c;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    -webkit-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
}
.button:hover {
    box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
    -webkit-transform: translate(0px, -10px) scale(1.2);
    transform: translate(0px, -10px) scale(1.2);
}
.navbar {
    --bs-navbar-padding-y: 1.5rem;
    --bs-navbar-nav-link-padding-x: 13px;
}
@media only screen and (max-device-width: 1215px)  {
    a.nav-link {
        font-size: small !important;
        font-weight: 700 !important;
    }
}
@media screen and (max-device-width: 564.98px){
    h3.sect1-h3{
        font-size: small !important;
        text-align: center !important;
    }
}
button.sec1-btn {
    border-radius: 35px;
    background-color: #1c9d1c;
    border: none;
    color: #fff;
    text-align: center;
    font-size: larger;
    padding: 6px 2px;
    width: 220px;
    transition: all 0.5s;
    cursor: pointer;
    /* margin: 36px; */
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
}
.navbar-toggler:focus{
    --bs-navbar-toggler-focus-width: 0rem;
    box-shadow: 0 0 0 0 !important;
}
.navbar-light .navbar-nav .nav-link {
    color: rgb(0 0 0) !important;
}
@media only screen and (min-device-width: 1440.98px){
    .navbar-expand-lg .navbar-nav .nav-link{
        padding: 2px 15px !important;
    }
}

.nav-btn{
    margin-left: auto;
}
.navbar-expand-lg .navbar-collapse{
    justify-content: space-between;
}
.navbar-nav{
    justify-content: flex-end;
}
@media screen and (max-width: 1024.98px){
    .navbar-nav a,.navbar-expand-lg .navbar-nav .nav-link{
        padding-right: 0px !important;
        --bs-navbar-nav-link-padding-x: 10px !important;

    }
    h3.sect1-h3, p.sect1-p{
        font-size: medium !important;
    }
    h1.sect1-h1{
        font-size: 45px !important;
    }
}
@media (min-width: 992px){
    .navbar-expand-lg .navbar-collapse {
        font-size: large !important;
        font-weight: 600 !important;
    }
}
button.button.sec1-btn.col-xs-2.col-sm-2.col-md-2.col-lg-2.col-xl-2.btn.btn-primary:hover {
    color: #000 !important;
    background-color: #ffffff !important;
    border: 2px solid #1c9d1c !important;
    padding-right: 24px;
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}
button.button.sec1-btn.col-xs-2.col-sm-2.col-md-2.col-lg-2.col-xl-2.btn.btn-primary:focus{
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 0%) !important;
    background-color: #1c9d1c;
    border-color: #1c9d1c;
}
button.button.sec1-btn.col-xs-2.col-sm-2.col-md-2.col-lg-2.col-xl-2.btn.btn-primary:first-child:active{
    background-color: #1c9d1c !important;
}
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #1c9d1c !important;
}
.dropdown-menu-toggle a.dropdown-toggle.nav-link::after{
    transform: rotate(266deg) !important;
}
.dropdown-menu-toggle div.dropdown-menu{
    left: 11rem !important;
    top: 0rem !important;
    width: fit-content !important;
    padding-right: 10px !important;
}
@media screen and (max-width: 992px){
    .dropdown-menu.show{
        font-size: small !important;
    }
    .dropdown-menu-toggle a.dropdown-toggle.nav-link{
        font-weight: 400 !important;
        padding: 0px 7px !important;
    }
    .dropdown-menu-toggle div.dropdown-menu{
        position: absolute !important;
        width: fit-content !important;
        padding-right: 10px !important;
    }
}
@media screen and (max-width: 600px){
    .dropdown-menu-toggle div.dropdown-menu{
        left: 180px !important;
    }
}
a.dropdown-item{
    margin-right: 10px;
}
@media screen and (max-width: 991px) {
    .navbar-nav .dropdown-menu {
        overflow-y: scroll !important;
        height: 145px !important;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        /*overflow: hidden;*/
    }
}
