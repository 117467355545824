
.page-content{
    padding-top: 5rem;
}
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #000000;
}

.section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: rgba(221, 221, 221, 0.01);
    bottom: 1px;
    left: calc(50% - 60px);
}

.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #1c9d1c;
    bottom: 0;
    left: calc(50% - 20px);
}

.section-title p {
    margin-bottom: 0;
}

.container {
    position: relative;
    text-align: center;
    color: white;
}
.Section-1 {
    background: url("../img/banner1.png") no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: auto;
    height: 900px;
}
.Section-1::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(28, 27, 27, 0.8);
}
img.sect1-banner1{
    width: -webkit-fill-available;
    height: max-content;
    z-index: -1;
    filter: brightness(50%);
}
h3.sect1-h3{
    text-align: left;
    color: #ffffff;
    font-size: larger;
    font-weight: 700;
    line-height: 2em;
    letter-spacing: 4px;
    margin-bottom: 3rem;
}
@keyframes pop-word {
    to {
        transform: rotateX(0);
    }
}

@keyframes show {
    to {
        opacity: 1;
    }
}

@keyframes bar-scale {
    to {
        transform: scaleY(1);
    }
}

@keyframes sparkle {
    0% {
        transform: scale(0);
    }

    60% {
        transform: scale(1) translate(4px, 1px) rotate(8deg);
    }

    100% {
        transform: scale(0) translate(4px, 1px) rotate(8deg);
    }
}

@keyframes shimmer {
    to {
        text-shadow: 0 0 8px red;
    }
}


h1.sect1-h1 {
    color: #faff00;
    font-family: "Playfair Display", Vidaloka, serif;
    font-size: 4rem;
    font-weight: 600;
    perspective: 500px;
    line-height: 85px;
    letter-spacing: 0px;
    text-shadow: 0px 0px 17px #000000
}

.word {
    display: block;

    animation: show 0.01s forwards, pop-word 1.5s forwards;
    animation-timing-function: cubic-bezier(0.14, 1.23, 0.33, 1.16);
    opacity: 0;

    transform: rotateX(120deg);
    transform-origin: 50% 100%;
}

.word:nth-of-type(2) {

    animation-delay: 1.5s;
    color: #faff00;
    letter-spacing: 0px;
    text-shadow: 0px 0px 17px #000000
}

.superscript {
    position: relative;
    animation-delay: 3.6s;

    animation-duration: 0.25s;
    animation-name: shimmer;

    vertical-align: text-top;
}

/* bars */
.superscript::before {
    --bar-width: 25%;

    position: absolute;

    top: 37%;
    left: 47%;
    width: 14%;
    height: 48%;

    animation: bar-scale 0.25s linear 3s 1 forwards;

    background: linear-gradient(
            to right,
            white var(--bar-width),
            transparent var(--bar-width) calc(100% - var(--bar-width)),
            white calc(100% - var(--bar-width))
    );

    content: "";

    transform: scaleY(var(--bar-scale-y));
}

/* sparkle */
.superscript::after {
    --size: 10rem;

    position: absolute;

    top: -5%;
    left: -85%;

    width: var(--size);
    height: var(--size);

    animation: sparkle 0.4s linear 3.5s 1 forwards;

    background: radial-gradient(
            circle at center,
            rgb(252 249 241 / 94%) 0% 7%,
            transparent 7% 100%
    ),
    conic-gradient(
            transparent 0deg 18deg,
            var(--sparkle-color) 18deg,
            transparent 20deg 40deg,
            var(--sparkle-color) 40deg,
            transparent 43deg 87deg,
            var(--sparkle-color) 87deg,
            transparent 95deg 175deg,
            var(--sparkle-color) 175deg,
            transparent 178deg 220deg,
            var(--sparkle-color) 220deg,
            transparent 222deg 270deg,
            var(--sparkle-color) 270deg,
            transparent 275deg 300deg,
            var(--sparkle-color) 300deg,
            transparent 303deg 360deg
    );

    border-radius: 50%;
    clip-path: polygon(
            50% 0,
            59.13% 26.64%,
            85.13% -2.35%,
            100% 50%,
            50% 100%,
            0 50%,
            31.39% 34.86%
    );

    content: "";

    filter: blur(1px);

    transform: scale(0);
}

@media screen and (max-width: 600px) {
    h1.sect1-h1 {
        font-size: 5rem;
    }

    /* sparkle */
    .superscript::after {
        --size: 6rem;
    }
}
p.sect1-p{
    text-align: left;
    color: #ffffff;
    font-size: larger;
    font-weight: 600;
    line-height: 1.3em;
    letter-spacing: 0px;
}
button.sec1-btn {
    border-radius: 35px;
    background-color: #1c9d1c;
    border: none;
    color: #fff;
    text-align: center;
    font-size: larger;
    padding: 6px 2px;
    width: 220px;
    transition: all 0.5s;
    cursor: pointer;
    /* margin: 36px; */
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
}

button.sec1-btn{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

button.sec1-btn:after {
    content: '»';
    position: absolute;
    opacity: 0;
    right: -20px;
    transition: 0.5s;
}

button.sec1-btn:hover{
    padding-right: 24px;
    padding-left:8px;
}

button.sec1-btn:hover:after {
    opacity: 1;
    right: 10px;
}
 /*Set the image-container as a relative positioned element */
.image-container {
    position: relative;
    display: inline-block;
    width: -webkit-fill-available;
}

/* Style the image */
.image-container img {
    display: block;
    width: 100%;
    height: auto;
}

/* Style the overlay-text */
.overlay-text {
    position: absolute;
    top: 50%; /* Adjust this value to vertically center the text */
    left: 50%; /* Adjust this value to horizontally center the text */
    transform: translate(-50%, -50%); /* Center the text precisely */
    color: #fff; /* Set the text color */
    padding: 10px 20px; /* Add some padding to the text */
    font-size: 20px; /* Adjust the font size */
    /*   text-align: center; /* Center the text horizontally */
}
.space-Section {
    height: 100px;
}
h2.sec2-h2 {
    color: #000000;
    font-size: 3em;
    font-weight: 700;
    text-align: center;
}
hr {
    border-width: 2px;
}
.hr-line {
    color: black;
    width: 10%;
    margin: auto;
}
.sect2-subtitle {
    text-align: center;
    color: #3a3a3a;
    margin: 40px auto;
    /*padding: 0px 200px 0px 200px;*/
}
/*h5.sect2-pg {*/
/*    padding: 0px 200px 0px 200px;*/
/*}*/
.sect2-icon1{
    text-align: center;
    margin-bottom: 17px;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}
img.card1-icon1 {
    height: 50px;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}
.sect2-card1title {
    margin-bottom: 40px;
    color: #000000;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
}
.sect2-card1pg {
    margin-bottom: 1.6em;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: #000000;
    font-size: 14px;
}


.card {
    position: relative;
    height: 400px;
    width: 100%;
    margin: 10px 0;
    transition: ease all 2s;
    perspective: 1200px;
}
.card:hover .cover {
    transform: rotateX(0deg) rotateY(-180deg);
}
.card:hover .cover:before {
    transform: translateZ(30px);
}
.card:hover .cover:after {
    background-color: black;
}
.card:hover .cover h1 {
    transform: translateZ(100px);
}
.card:hover .cover .price {
    transform: translateZ(60px);
}
.card:hover .cover a {
    transform: translateZ(-60px) rotatey(-180deg);
}
.card .cover {
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    transition: ease all 2s;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 30px;
}
.card .cover:before {
    content: '';
    position: absolute;
    border: 5px solid rgb(0 0 0);
     box-shadow: 0 0 12px rgba(0, 0, 0, .3);
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 2;
    transition: ease all 2s;
    transform-style: preserve-3d;
    transform: translateZ(0px);
    filter: brightness(1.5);
}
.card .cover:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    transition: ease all 1.3s;
    border-radius: 30px;
    /*background: rgba(255, 255, 255, 0.4);*/
}
.card .cover.item-a {
    background-color: #ffffff;
    box-shadow: 0 0 16px rgb(24 24 25);
}
.card .cover.item-b {
    background-color: #ffffff;
}
.card .cover.item-c {
    background-color: #ffffff;
}
.card .cover h1 {
    font-weight: 600;
    position: absolute;
    bottom: 55px;
    left: 50px;
    color: white;
    transform-style: preserve-3d;
    transition: ease all 2s;
    z-index: 3;
    font-size: 3em;
    transform: translateZ(0px);
}
.card .cover .price {
    font-weight: 200;
    position: absolute;
    top: 55px;
    right: 50px;
    color: white;
    transform-style: preserve-3d;
    transition: ease all 2s;
    z-index: 4;
    font-size: 2em;
    transform: translateZ(0px);
}
.card .card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #ffffff;
    transform-style: preserve-3d;
    transition: ease all 2s;
    transform: translateZ(-1px);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0%;
    border-radius: 30px;
}
.card .card-back a {
    transform-style: preserve-3d;
    transition:  ease background 0.5s;
    transform: translateZ(-1px) rotatey(-180deg);
    background: black;
    border: 1px solid white;
    font-weight: 200;
    font-size: 1.3em;
    color: white;
    padding: 14px 32px;
    outline: none;
    text-decoration: none;
    border-radius: 30px;
}
.card .card-back a:hover {
    background-color: #000000;
    color: #ffffff;
}
.Sec2-Container{
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

/*section-3 start*/

.portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(69, 80, 91, 0.8);
}

.portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 35px;
    left: 35px;
    border-top: 3px solid #d7dce1;
    border-left: 3px solid #d7dce1;
    transition: all 0.5s ease 0s;
    z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-bottom: 3px solid #d7dce1;
    border-right: 3px solid #d7dce1;
    transition: all 0.5s ease 0s;
    z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 a {
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
}

.portfolio .portfolio-wrap .portfolio-info p {
    color: #ffffff;
    font-size: 25px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
    color: #45505b;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
    color: #148af9;
}

.portfolio .portfolio-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
    top: 15px;
    left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
    bottom: 15px;
    right: 15px;
}

.portfolio-details {
    padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
    width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #0563bb;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #0563bb;
}

.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(69, 80, 91, 0.08);
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
}

.portfolio-details .portfolio-description {
    padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0;
}


/*** section-4 Start ***/
.testimonial-item {
    border: 1px solid #ffffff;
    padding: 20px 20px;
    width: 50%;
}

.testimonial-carousel .owl-item img {
    width: 100px;
    height: 100px;
}

.testimonial-carousel.owl-rtl .testimonial-item {
    direction: ltr !important;
}
/*** section-4 End ***/


@media screen and (max-device-width: 425.98px){
    h3.sect1-h3, span.word{
        font-size: 30px !important;
        margin-bottom: 0px !important;
    }
    .word:nth-of-type(2) {
        margin-top: -35px !important;
        text-align: center;
    }
}


/*AboutUs page*/

h2{
    color: black;
}
p{
    color: black;
}

section.section-about {
    background: url("../img/banner1.png") no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: auto;
    height: 500px;
}
section.section-about::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(28, 27, 27, 0.8);
}

.counter{
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    background: #fff;
    text-align: center;
    width: 190px;
    padding: 25px 30px 10px;
    margin: 0 auto;
    border-radius: 30px 0 ;
    box-shadow: 10px 10px 0 rgba(0,0,0,0.05);
    position: relative;
    z-index: 1;
}
.counter:before{
    content: "";
    background: #51d658;
    border-radius: 15px 0 30px 0;
    box-shadow: inset 0 4px 8px rgba(0,0,0,0.15);
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 27px;
    z-index: -1;
}
.counter .counter-icon{
    font-size: 35px;
    margin: 0 0 10px;
    transform: rotateX(0deg);
    transition: all 0.3s ease 0s;
}
.counter:hover .counter-icon{
    transform: rotateX(360deg);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.counter h3{
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 20px;
}
.counter .counter-value{
    color: #666;
    background: #fff;
    font-size: 33px;
    font-weight: 600;
    text-align: right;
    line-height: 60px;
    width: 90%;
    height: 50px;
    padding: 0 15px;
    margin: 0 0 0 -20px;
    display: block;
}
.counter.orange:before {
    background-color: #fc8720;
}
.counter.purple:before {
    background-color: #BF5DFE;
}
.counter.pink:before{ background-color: #FF5D94; }
@media screen and (max-width:990px){
    .counter{ margin-bottom: 40px; }
}


.text-muted, p.section_subtitle.mx-auto.text-muted{
    color: #ffffff !important;
}

.section-title .sub-style {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: #fc8720;
}

.section-title .sub-style::before {
    content: "";
    width: 100px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-top: 8px;
    margin-left: -100px;
    border: 1px solid #1e4898 !important;
}

.section-title .sub-style::after {
    content: "";
    width: 50px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-bottom: 5px;
    margin-left: -50px;
    border: 1px solid #1e4898 !important
}

.sub-title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: #1e4898;
}

.sub-title::before {
    content: "";
    width: 100px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-top: 8px;
    margin-right: -100px;
    border: 1px solid #1e4898 !important;
}

.sub-title::after {
    content: "";
    width: 50px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-bottom: 8px;
    margin-right: -50px;
    border: 1px solid #1e4898 !important;
}
h4.about-h4-subtxt {
    color: black;
}
h4.about-h4-subtxt:hover {
     color: #ffffff;
}

.box {
    position: relative;
    width: 100%;
    /*padding-right: 15px;*/
    /*padding-left: 15px;*/
}
.our-services{
    /*margin-top: 75px;*/
    /*padding-bottom: 30px;*/
    padding: 40px 15px 22px;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;
    transition: all .4s ease-in-out;
    box-shadow: 0 0 25px 0 rgba(20,27,202,.17)
}
.our-services .icon{margin-bottom: -21px;
    transform: translateY(-50%);text-align: center}
.our-services:hover h4,
.our-services:hover p{color: #fff}
.speedup:hover{box-shadow: 0 0 25px 0 rgba(20,27,201,.05);cursor: pointer;
    background-image: linear-gradient(-45deg, #1c9d1c 0%, #22dd73 100%);}
.settings:hover{box-shadow: 0 0 25px 0 rgba(20,27,201,.05);cursor: pointer;
    background-image: linear-gradient(-45deg, #1c9d1c 0%, #22dd73 100%);}
.privacy:hover{box-shadow: 0 0 25px 0 rgba(20,27,201,.05);cursor: pointer;
    background-image: linear-gradient(-45deg, #1c9d1c 0%, #22dd73 100%);}
.backups:hover{box-shadow: 0 0 25px 0 rgba(20,27,201,.05);cursor: pointer;
    background-image: linear-gradient(-45deg, #1c9d1c 0%, #22dd73 100%);}
.ssl:hover{box-shadow: 0 0 25px 0 rgba(20,27,201,.05);cursor: pointer;
    background-image: linear-gradient(-45deg, #1c9d1c 0%, #22dd73 100%);}
.database:hover{box-shadow: 0 0 25px 0 rgba(20,27,201,.05);cursor: pointer;
    background-image: linear-gradient(-45deg, #1c9d1c 0%, #22dd73 100%)}


h5.mb-4{
    color: black;
}

.card .content {
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    transition: ease all 2s;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.card .content:before {
    content: '';
    position: absolute;
    border: 5px solid rgb(0 0 0);
    box-shadow: 0 0 12px rgba(0, 0, 0, .3);
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 2;
    transition: ease all 2s;
    transform-style: preserve-3d;
    transform: translateZ(0px);
    filter: brightness(1.5);
}

/* Clients Section
--------------------------------*/

@media (min-width: 992px){
    img.img-fluid.img2{
        max-width: 300% !important;
        width: 150% !important;
        margin-top: 1rem !important;
    }
    img.img-fluid.img3{
        margin-top: -2rem !important;
    }
    img.img-fluid.img4{
        width: 130% !important;
        max-width: 145% !important;
    }
    img.img-fluid.img6{
        width: 145% !important;
        max-width: 225% !important;
    }
    img.img-fluid.img7{
        width: 145% !important;
        max-width: 200% !important;
        margin-top: 3rem !important;
    }
}
@media (max-width: 991px){
    img.img-fluid.img2 {
        max-width: 170% !important;
        width: 170% !important;
        margin-top: 1rem !important;
    }
    img.img-fluid.img4 {
        width: 170% !important;
        max-width: 145% !important;
    }
    img.img-fluid.img6 {
        max-width: 120% !important;
        width: 130% !important;
    }
    img.img-fluid.img7 {
        margin-top: 2rem !important;
    }
}

#clients {
    padding: 60px 0;
}

#clients img {
    opacity: 0.5;
    transition: 0.3s;
}

#clients img:hover {
    opacity: 1;
}

#clients .swiper-pagination {
    margin-top: 30px;
    position: relative;
}

#clients .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #18d26e;
}

#clients .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #18d26e;
}
img.img-fluid.affi1 {
    width: 50% !important;
}
img.img-fluid.affi2{
    /*margin: -11px 0px !important;*/
    width: 65% !important;
}
img.img-fluid.affi3 {
    width: 50% !important;
}
img.img-fluid.affi4 {
    margin: 20px 0px !important;
    width: 40% !important;
}
img.img-fluid.affi5 {
    margin: 25px 0px !important;
    width: 50% !important;
}





































/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 992px) {
    .display-1 {
        font-size: 2.8rem;
    }
}
@media (max-width: 768px) {
    .display-1 {
        font-size: 2.45rem;
        font-size: calc( 1.875rem + (3.5 - 1.875) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.1 * (1.875rem + (3.5 - 1.875) * ((100vw - 20rem) / (48 - 20))));
    }
    .display-2 {
        font-size: 2.24rem;
        font-size: calc( 1.63rem + (2.8 - 1.63) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.3 * (1.63rem + (2.8 - 1.63) * ((100vw - 20rem) / (48 - 20))));
    }
    .display-4 {
        font-size: 0.9rem;
        font-size: calc( 1.04375rem + (1.125 - 1.04375) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.4 * (1.04375rem + (1.125 - 1.04375) * ((100vw - 20rem) / (48 - 20))));
    }
    .display-5 {
        font-size: 1.8rem;
        font-size: calc( 1.4375rem + (2.25 - 1.4375) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.4 * (1.4375rem + (2.25 - 1.4375) * ((100vw - 20rem) / (48 - 20))));
    }
    .display-7 {
        font-size: 1rem;
        font-size: calc( 1.0875rem + (1.25 - 1.0875) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.4 * (1.0875rem + (1.25 - 1.0875) * ((100vw - 20rem) / (48 - 20))));
    }
}

.cid-tpT23XlIIt {
    z-index: 1000;
    width: 100%;
    background: #e8e8e8 !important;
}
.cid-tpT23XlIIt .menu_box {
    background: rgba(255, 255, 255, 0.7) !important;
    padding: 0;
    border-radius: 100px;
    position: relative;
}
.cid-tpT23XlIIt .menu_box .dropdown-submenu {
    margin: 0;
    left: 100%;
}
.cid-tpT23XlIIt .menu_box::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    border-radius: 100px;
}
@media (max-width: 991px) {
    .cid-tpT23XlIIt .menu_box .navbar.opened,
    .cid-tpT23XlIIt .menu_box .navbar-collapse {
        background-color: #ffffff !important;
        transition: all 0s ease 0s;
    }
}
.cid-tpT23XlIIt nav.navbar {
    position: fixed;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.cid-tpT23XlIIt .navbar-dropdown.navbar-fixed-top {
    position: relative;
    background: transparent;
}
.cid-tpT23XlIIt .navbar-dropdown.navbar-fixed-top.navbar-short {
    position: fixed;
    background: transparent;
}
.cid-tpT23XlIIt .navbar-dropdown.navbar-fixed-top.navbar-short .menu {
    color: transparent !important;
}
.cid-tpT23XlIIt .navbar-dropdown {
    padding: 0;
    background: transparent;
}
@media (max-width: 992px) {
    .cid-tpT23XlIIt .navbar-dropdown {
        top: 0;
    }
}
.cid-tpT23XlIIt .icons-menu-main {
    display: flex;
    flex-wrap: wrap;
    max-width: 150px;
}
@media (max-width: 991px) {
    .cid-tpT23XlIIt .icons-menu-main {
        max-width: 100%;
        margin: 1rem 0 1rem 1rem;
    }
}
@media (max-width: 991px) {
    .cid-tpT23XlIIt .mbr-section-btn-main {
        margin-top: 1rem;
    }
}
.cid-tpT23XlIIt .btn {
    min-height: 55px;
    box-shadow: none;
    margin-top: 0;
}
.cid-tpT23XlIIt .btn:hover {
    box-shadow: none;
}
@media (min-width: 992px) {
    .cid-tpT23XlIIt .offcanvas {
        padding: 12rem 80px 0;
        width: 40%;
        background-color: #101511;
    }
    .cid-tpT23XlIIt .offcanvas_image img {
        width: auto;
        object-fit: cover;
        display: inline-block;
    }
    .cid-tpT23XlIIt .offcanvas-header {
        position: relative;
        padding: 0;
    }
    .cid-tpT23XlIIt .offcanvas-header .btn-close {
        position: absolute;
        top: -70px;
        right: 0;
        width: 35px;
        height: 30px;
    }
    .cid-tpT23XlIIt .offcanvas-body {
        text-align: center;
        padding: 0;
    }
    .cid-tpT23XlIIt .offcanvas-body .mbr-text,
    .cid-tpT23XlIIt .offcanvas-body .mbr-section-subtitle {
        margin-top: 14px;
    }
    .cid-tpT23XlIIt .offcanvas-body .offcanvas_contact {
        margin: 35px 0;
    }
    .cid-tpT23XlIIt .offcanvas_box button.btn_offcanvas {
        outline: none;
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: all 0.2s;
        position: relative;
        align-self: center;
        margin-right: 20px;
    }
    .cid-tpT23XlIIt .offcanvas_box button.btn_offcanvas .hamburger span {
        position: absolute;
        right: 0;
        width: 40px;
        height: 2px;
        border-right: 5px;
        background-color: #101511;
    }
    .cid-tpT23XlIIt .offcanvas_box button.btn_offcanvas .hamburger span:nth-child(1) {
        top: 18px;
        transition: all 0.2s;
    }
    .cid-tpT23XlIIt .offcanvas_box button.btn_offcanvas .hamburger span:nth-child(2) {
        top: 25px;
        transition: all 0.2s;
    }
    .cid-tpT23XlIIt .offcanvas_box button.btn_offcanvas:hover .hamburger span {
        width: 36px;
    }
    .cid-tpT23XlIIt .offcanvas_box button.btn_offcanvas:hover .hamburger span:nth-child(2) {
        width: 33px;
        transition-delay: 0.2s;
    }
    .cid-tpT23XlIIt ul.navbar-nav {
        padding-bottom: 1.5rem;
    }
    .cid-tpT23XlIIt .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after,
    .cid-tpT23XlIIt .link.dropdown-toggle::after {
        display: inline-block;
        width: 7px;
        height: 7px;
        margin-left: .5rem;
        margin-bottom: 2px;
        content: "";
        border: 2px solid;
        border-left: none;
        border-top: none;
        transform: rotate(-45deg);
    }
    .cid-tpT23XlIIt .link.dropdown-toggle::after {
        padding: 0 !important;
        transform: rotate(45deg);
    }
    .cid-tpT23XlIIt li.nav-item {
        position: relative;
        display: inline-block;
        padding: 1px 7px !important;
        vertical-align: middle;
        line-height: 2em !important;
        font-weight: 600 !important;
        text-decoration: none;
        letter-spacing: 0 !important;
        z-index: 1;
    }
    .cid-tpT23XlIIt .lg_brand {
        margin: 0 1rem;
    }
}
.cid-tpT23XlIIt .nav-item {
    margin: 4px 15px;
}
@media (min-width: 1200px) {
    .cid-tpT23XlIIt .nav-item {
        margin: 4px 20px;
    }
}
@media (max-width: 991px) {
    .cid-tpT23XlIIt .nav-item {
        margin: 0 !important;
    }
}
.cid-tpT23XlIIt .dropdown-menu {
    border-radius: 0;
    box-shadow: none;
    text-align: left;
}
@media (min-width: 992px) {
    .cid-tpT23XlIIt .dropdown-menu {
        padding: 18px 34px 22px;
        min-width: 250px;
        top: auto !important;
        left: -40px !important;
    }
    .cid-tpT23XlIIt .dropdown-menu.dropdown-submenu {
        left: 215px !important;
        top: -45% !important;
    }
}
@media (max-width: 991px) {
    .cid-tpT23XlIIt .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after,
    .cid-tpT23XlIIt .link.dropdown-toggle::after {
        display: inline-block;
        width: 7px;
        height: 7px;
        margin-left: .5rem;
        margin-bottom: 2px;
        content: "";
        border: 2px solid;
        border-left: none;
        border-top: none;
        transform: rotate(-45deg);
        right: 15px;
        position: absolute;
        margin-top: -2px;
    }
    .cid-tpT23XlIIt .show.dropdown-toggle[aria-expanded="true"]::after {
        transform: rotate(45deg);
        margin-top: -4px;
    }
    .cid-tpT23XlIIt .offcanvas_box {
        display: none;
    }
}
.cid-tpT23XlIIt .dropdown-item {
    border: none;
    font-weight: 400 !important;
}
.cid-tpT23XlIIt .nav-dropdown .link {
    font-weight: 400 !important;
    padding: 0 !important;
    margin: 0 !important;
}
.cid-tpT23XlIIt .nav-dropdown .link.dropdown-toggle::after {
    margin-left: 0.5rem;
    margin-top: 0;
}
.cid-tpT23XlIIt .container {
    display: flex;
    margin: auto;
}
.cid-tpT23XlIIt .iconfont-wrapper {
    color: #6e716f;
    font-size: 17px;
    margin-right: 10px;
    margin-bottom: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
}
.cid-tpT23XlIIt .iconfont-wrapper:last-child {
    margin-right: 0;
}
.cid-tpT23XlIIt .iconfont-wrapper:hover {
    opacity: .5;
}
.cid-tpT23XlIIt .navbar-caption {
    font-weight: 400;
    color: #f2f2f2;
}
.cid-tpT23XlIIt .navbar-nav {
    margin: 0 1rem;
}
@media (min-width: 992px) {
    .cid-tpT23XlIIt .navbar-nav {
        margin: 0;
    }
}
.cid-tpT23XlIIt .dropdown-menu,
.cid-tpT23XlIIt .navbar.opened {
    background-color: true !important;
}
.cid-tpT23XlIIt .nav-item:focus,
.cid-tpT23XlIIt .nav-link:focus {
    outline: none;
}
.cid-tpT23XlIIt .dropdown .dropdown-menu .dropdown-item {
    width: auto;
    transition: all 0.25s ease-in-out;
}
.cid-tpT23XlIIt .dropdown .dropdown-menu .dropdown-item::after {
    right: 0.5rem;
}
.cid-tpT23XlIIt .dropdown .dropdown-menu .dropdown-item .mbr-iconfont {
    margin-right: 0.5rem;
    vertical-align: sub;
}
.cid-tpT23XlIIt .dropdown .dropdown-menu .dropdown-item .mbr-iconfont:before {
    display: inline-block;
    transform: scale(1, 1);
    transition: all 0.25s ease-in-out;
}
.cid-tpT23XlIIt .collapsed .dropdown-menu .dropdown-item:before {
    display: none;
}
.cid-tpT23XlIIt .collapsed .dropdown .dropdown-menu .dropdown-item {
    padding: 0.235em 1.5em 0.235em 1.5em !important;
    transition: none;
    margin: 0 !important;
}
.cid-tpT23XlIIt .navbar {
    min-height: 100px;
    padding: 20px 0;
    transition: all 0.3s;
    border-bottom-width: 0px;
}
.cid-tpT23XlIIt .navbar.opened {
    transition: all 0.3s;
}
.cid-tpT23XlIIt .navbar .dropdown-item {
    padding: 0;
    margin: 8px 0;
}
.cid-tpT23XlIIt .navbar .navbar-logo img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 100%;
}
.cid-tpT23XlIIt .navbar .navbar-collapse {
    justify-content: space-between;
    z-index: 1;
}
.cid-tpT23XlIIt .navbar.collapsed {
    justify-content: center;
}
.cid-tpT23XlIIt .navbar.collapsed .nav-item .nav-link::before {
    display: none;
}
.cid-tpT23XlIIt .navbar.collapsed.opened .dropdown-menu {
    top: 0;
}
.cid-tpT23XlIIt .navbar.collapsed .dropdown-menu .dropdown-submenu {
    left: 0 !important;
}
.cid-tpT23XlIIt .navbar.collapsed .dropdown-menu .dropdown-item:after {
    right: auto;
}
.cid-tpT23XlIIt .navbar.collapsed ul.navbar-nav li {
    margin: auto;
}
.cid-tpT23XlIIt .navbar.collapsed .dropdown-menu .dropdown-item {
    padding: 0.25rem 1.5rem;
    text-align: left;
}
.cid-tpT23XlIIt .navbar.collapsed .icons-menu {
    padding: 0;
}
@media (max-width: 991px) {
    .cid-tpT23XlIIt .navbar .nav-item {
        padding: .5rem 0;
    }
    .cid-tpT23XlIIt .navbar .navbar-collapse {
        padding: 34px 0;
        border-radius: 25px;
    }
    .cid-tpT23XlIIt .navbar .nav-item .nav-link::before {
        display: none;
    }
    .cid-tpT23XlIIt .navbar.opened .dropdown-menu {
        top: 0;
    }
    .cid-tpT23XlIIt .navbar .dropdown-menu {
        padding: 6px 0 6px 15px;
    }
    .cid-tpT23XlIIt .navbar .dropdown-menu .dropdown-submenu {
        left: 0 !important;
    }
    .cid-tpT23XlIIt .navbar .dropdown-menu .dropdown-item:after {
        right: auto;
        margin-top: -0.4rem;
    }
    .cid-tpT23XlIIt .navbar .navbar-logo img {
        height: 3rem !important;
    }
    .cid-tpT23XlIIt .navbar ul.navbar-nav {
        overflow: hidden;
    }
    .cid-tpT23XlIIt .navbar ul.navbar-nav li {
        margin: 0;
    }
    .cid-tpT23XlIIt .navbar .dropdown-menu .dropdown-item {
        padding: 0 !important;
        margin: 0;
        margin-top: 8px;
        text-align: left;
    }
    .cid-tpT23XlIIt .navbar .navbar-brand {
        flex-shrink: initial;
        flex-basis: auto;
        word-break: break-word;
        padding-right: 2rem;
    }
    .cid-tpT23XlIIt .navbar .navbar-toggler {
        flex-basis: auto;
    }
    .cid-tpT23XlIIt .navbar .icons-menu {
        padding: 0;
    }
}
.cid-tpT23XlIIt .navbar.navbar-short {
    min-height: 60px;
}
.cid-tpT23XlIIt .navbar.navbar-short .navbar-brand {
    min-height: 45px;
    padding: 0 12px;
}
.cid-tpT23XlIIt .navbar-brand {
    min-height: 45px;
    flex-shrink: 0;
    align-items: center;
    padding: 0 12px;
    transition: all 0.3s;
    word-break: break-word;
    z-index: 1;
    border-radius: 100px;
    background-color: #101511;
    margin: 16px;
}
.cid-tpT23XlIIt .navbar-brand .navbar-caption {
    line-height: inherit !important;
}
.cid-tpT23XlIIt .navbar-brand .navbar-logo a {
    outline: none;
}
.cid-tpT23XlIIt .dropdown-item.active,
.cid-tpT23XlIIt .dropdown-item:active {
    background-color: transparent;
}
.cid-tpT23XlIIt .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
}
.cid-tpT23XlIIt .nav-dropdown .link.dropdown-toggle {
    margin-right: 1.667em;
}
.cid-tpT23XlIIt .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
    margin-right: 0;
    padding: 0.667em 1.667em;
}
.cid-tpT23XlIIt .navbar.navbar-expand-lg .dropdown .dropdown-menu {
    background: #ffffff;
}
.cid-tpT23XlIIt .navbar.navbar-expand-lg .dropdown .dropdown-menu .dropdown-submenu {
    margin: 0;
    left: 100%;
}
.cid-tpT23XlIIt .navbar .dropdown.open > .dropdown-menu {
    display: block;
}
.cid-tpT23XlIIt ul.navbar-nav {
    flex-wrap: wrap;
    padding-bottom: 0;
}
.cid-tpT23XlIIt .navbar-buttons {
    text-align: center;
    min-width: 170px;
}
.cid-tpT23XlIIt button.navbar-toggler {
    outline: none;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    align-self: center;
    color: #ffffff;
    background: #ffffff;
    margin-right: 15px;
}
.cid-tpT23XlIIt button.navbar-toggler .hamburger span {
    position: absolute;
    right: 10px;
    margin-top: 14px;
    width: 26px;
    height: 2px;
    border-right: 5px;
    background-color: #101511;
}
.cid-tpT23XlIIt button.navbar-toggler .hamburger span:nth-child(1) {
    top: 0;
    transition: all 0.2s;
}
.cid-tpT23XlIIt button.navbar-toggler .hamburger span:nth-child(2) {
    top: 8px;
    transition: all 0.15s;
}
.cid-tpT23XlIIt button.navbar-toggler .hamburger span:nth-child(3) {
    top: 8px;
    transition: all 0.15s;
}
.cid-tpT23XlIIt button.navbar-toggler .hamburger span:nth-child(4) {
    top: 16px;
    transition: all 0.2s;
}
.cid-tpT23XlIIt nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all 0.2s;
}
.cid-tpT23XlIIt nav.opened .hamburger span:nth-child(2) {
    transform: rotate(45deg);
    transition: all 0.25s;
}
.cid-tpT23XlIIt nav.opened .hamburger span:nth-child(3) {
    transform: rotate(-45deg);
    transition: all 0.25s;
}
.cid-tpT23XlIIt nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all 0.2s;
}
.cid-tpT23XlIIt a.nav-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.cid-tpT23XlIIt .icons-menu {
    flex-wrap: nowrap;
    display: flex;
    justify-content: center;
    padding: 0;
    text-align: center;
    margin-bottom: 35px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .cid-tpT23XlIIt .navbar {
        height: 70px;
    }
    .cid-tpT23XlIIt .navbar.opened {
        height: auto;
    }
    .cid-tpT23XlIIt .nav-item .nav-link:hover::before {
        width: 175%;
        max-width: calc(100% + 2rem);
        left: -1rem;
    }
}
.cid-tpT23XlIIt .navbar-dropdown .navbar-logo {
    margin-right: 5px;
}
@media (min-width: 768px) {
    .cid-tpT23XlIIt .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }
}
.cid-tpT23XlIIt .mbr-section-btn-main .btn {
    max-height: 45px;
    min-height: auto;
    min-width: 160px;
    margin: 16px;
}
.cid-tpT23XlIIt .navbar-caption:hover {
    color: #eafa72;
}
@media (min-width: 992px) {
    .cid-tpT23XlIIt .dropdown-menu.dropdown-submenu {
        left: 175px !important;
        top: -45% !important;
    }
}
.cid-tpT23XlIIt .nav-link:hover {
    color: #6e716f !important;
}
.cid-tpT23XlIIt .mbr-section-subtitle {
    color: #f2f2f2;
}
.cid-tpT23XlIIt .mbr-text {
    color: #f2f2f2;
    text-align: center;
}
.cid-tpT23XlIIt .text_widget {
    color: #f2f2f2;
}
.cid-tpT23XlIIt .mbr-section-subtitle,
.cid-tpT23XlIIt .text_widget,
.cid-tpT23XlIIt .mbr-section-btn {
    text-align: center;
}
.cid-tpT23XlIIt a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption):hover {
    background-image: none;
}
.cid-tpT24jhW5V {
    background-color: #e8e8e8;
}
.cid-tpT24jhW5V .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT24jhW5V .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT24jhW5V .title-wrapper {
    padding: 50px 80px 0 0;
}
@media (max-width: 992px) {
    .cid-tpT24jhW5V .title-wrapper {
        margin-bottom: 32px;
        padding: 0;
    }
}
.cid-tpT24jhW5V .title-wrapper .mbr-section-title {
    margin-bottom: 60px;
    position: relative;
    z-index: 1;
}
@media (max-width: 992px) {
    .cid-tpT24jhW5V .title-wrapper .mbr-section-title {
        margin-bottom: 32px;
    }
}
.cid-tpT24jhW5V .title-wrapper .mbr-text {
    margin-bottom: 60px;
    position: relative;
    z-index: 1;
}
@media (max-width: 992px) {
    .cid-tpT24jhW5V .title-wrapper .mbr-text {
        margin-bottom: 32px;
    }
}
.cid-tpT24jhW5V .title-wrapper .mbr-section-btn {
    position: relative;
}
.cid-tpT24jhW5V .title-wrapper .mbr-section-btn::before {
    content: '';
    position: absolute;
    left: 3rem;
    top: -3rem;
    border-radius: 100%;
    width: 200px;
    height: 200px;
    filter: blur(25px) blur(25px);
    background-color: #eafa72;
}
.cid-tpT24jhW5V .title-wrapper .mbr-section-btn .btn {
    position: relative;
    z-index: 1;
    min-height: 77px;
    min-width: 280px;
}
@media (max-width: 992px) {
    .cid-tpT24jhW5V .title-wrapper .mbr-section-btn .btn {
        min-height: 71px;
        min-width: 250px;
    }
}
.cid-tpT24jhW5V .image-wrapper {
    position: relative;
}
.cid-tpT24jhW5V .image-wrapper::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 100%;
    width: 400px;
    height: 400px;
    filter: blur(35px) blur(35px);
    background-color: #eafa72;
}
@media (max-width: 992px) {
    .cid-tpT24jhW5V .image-wrapper::before {
        width: 250px;
        height: 250px;
    }
}
.cid-tpT24jhW5V .image-wrapper::after {
    content: '';
    position: absolute;
    right: .3rem;
    bottom: -3rem;
    border-radius: 100%;
    border: 2px solid #f2f2f2;
    width: 550px;
    height: 550px;
    background-color: transparent;
}
@media (max-width: 992px) {
    .cid-tpT24jhW5V .image-wrapper::after {
        width: 250px;
        height: 250px;
    }
}
@media (max-width: 768px) {
    .cid-tpT24jhW5V .image-wrapper::after {
        display: none;
    }
}
.cid-tpT24jhW5V .image-wrapper img {
    border-radius: 10px;
    box-shadow: 6px 6px 20px #6e716f;
}
.cid-tpT24jhW5V .image-wrapper .image_1 {
    width: 100%;
    max-height: 360px;
    object-fit: cover;
    z-index: 2;
    position: relative;
}
.cid-tpT24jhW5V .image-wrapper .image_2 {
    display: block;
    max-width: 390px;
    height: 250px;
    object-fit: cover;
    margin: -2rem 2rem 0 auto;
    z-index: 2;
    position: relative;
}
@media (max-width: 768px) {
    .cid-tpT24jhW5V .image-wrapper .image_2 {
        margin: 0;
    }
}
.cid-tpT24jhW5V .image-wrapper .image_3 {
    position: absolute;
    left: -5rem;
    bottom: 5rem;
    width: 310px;
    height: 200px;
    object-fit: cover;
    z-index: 1;
}
@media (max-width: 992px) {
    .cid-tpT24jhW5V .image-wrapper .image_3 {
        left: -1rem;
    }
}
@media (max-width: 768px) {
    .cid-tpT24jhW5V .image-wrapper .image_3 {
        position: static;
        width: 100%;
    }
}
.cid-tpT24jhW5V .mbr-section-title {
    color: #101511;
}
.cid-tpT24jhW5V .mbr-text {
    color: #6e716f;
}
.cid-tpT26jueGW {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #e8e8e8;
}
.cid-tpT26jueGW .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT26jueGW .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT26jueGW .text-container {
    background-color: #101511;
    border-radius: 100px;
    padding: 16px;
    min-height: 67px;
    margin: 0 20%;
}
@media (max-width: 768px) {
    .cid-tpT26jueGW .text-container {
        margin: 0 5%;
    }
}
.cid-tpT26jueGW .text-container .text-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 5px 0;
}
.cid-tpT26jueGW .text-container .text-wrapper .mbr-text {
    margin: 0 16px 0 0;
}
.cid-tpT26jueGW .text-container .text-wrapper .mbr-desc {
    margin: 0;
}
.cid-tpT26jueGW .text-container .text-wrapper .mbr-desc .mbr-iconfont {
    font-size: 20px;
}
.cid-tpT26jueGW .mbr-text {
    color: #e8e8e8;
}
.cid-tpT26jueGW .mbr-desc,
.cid-tpT26jueGW .mbr-iconfont {
    color: #e8e8e8;
}
.cid-tpT26jueGW .mbr-text,
.cid-tpT26jueGW .text-container {
    text-align: center;
}
.cid-tpT24QrIYC {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #e8e8e8;
}
.cid-tpT24QrIYC .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT24QrIYC .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT24QrIYC .row {
    justify-content: center;
}
.cid-tpT24QrIYC .title-wrapper {
    margin-bottom: 75px;
}
@media (max-width: 992px) {
    .cid-tpT24QrIYC .title-wrapper {
        margin-bottom: 45px;
    }
}
.cid-tpT24QrIYC .title-wrapper .mbr-section-title {
    margin-bottom: 0;
}
@media (min-width: 992px) {
    .cid-tpT24QrIYC .card {
        padding-left: 22px;
        padding-right: 22px;
    }
}
.cid-tpT24QrIYC .card .card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 45px 40px;
    border-radius: 40px;
    -webkit-flex: none;
    overflow: visible;
}
@media (max-width: 992px) {
    .cid-tpT24QrIYC .card .card-wrapper {
        padding: 45px 32px;
        min-height: 220px !important;
        margin-bottom: 24px;
    }
}
.cid-tpT24QrIYC .card .card-wrapper .num-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 32px;
}
.cid-tpT24QrIYC .card .card-wrapper .num-wrapper::before {
    content: '';
    position: absolute;
    right: 1.5rem;
    top: -2rem;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    filter: blur(25px) blur(25px);
}
.cid-tpT24QrIYC .card .card-wrapper .num-wrapper .mbr-number_1,
.cid-tpT24QrIYC .card .card-wrapper .num-wrapper .mbr-number_2,
.cid-tpT24QrIYC .card .card-wrapper .num-wrapper .mbr-number_3 {
    margin-bottom: 0;
}
.cid-tpT24QrIYC .card .card-wrapper .num-wrapper .mbr-iconfont {
    font-size: 50px;
    position: relative;
    margin: -1rem 3rem 0 0;
    z-index: 1;
    color: #101511;
}
@media (max-width: 992px) {
    .cid-tpT24QrIYC .card .card-wrapper .num-wrapper .mbr-iconfont {
        margin: -1rem 2rem 0 0;
    }
}
.cid-tpT24QrIYC .card .card-wrapper .text-wrapper .mbr-card-title_1,
.cid-tpT24QrIYC .card .card-wrapper .text-wrapper .mbr-card-title_2,
.cid-tpT24QrIYC .card .card-wrapper .text-wrapper .mbr-card-title_3 {
    margin-bottom: 5px;
}
.cid-tpT24QrIYC .card .card-wrapper .text-wrapper .mbr-text_1,
.cid-tpT24QrIYC .card .card-wrapper .text-wrapper .mbr-text_2,
.cid-tpT24QrIYC .card .card-wrapper .text-wrapper .mbr-text_3 {
    margin: 0;
}
.cid-tpT24QrIYC .card:nth-child(2) .card-wrapper {
    min-height: 220px;
    background-color: #f2f2f2;
}
.cid-tpT24QrIYC .card:nth-child(2) .card-wrapper .num-wrapper::before {
    background-color: #101511;
}
.cid-tpT24QrIYC .card:nth-child(3) .card-wrapper {
    min-height: 330px;
    background-color: #eafa72;
}
.cid-tpT24QrIYC .card:nth-child(3) .card-wrapper .num-wrapper::before {
    background-color: #f2f2f2;
}
.cid-tpT24QrIYC .card:last-child .card-wrapper {
    min-height: 265px;
    background-color: #101511;
}
.cid-tpT24QrIYC .card:last-child .card-wrapper .num-wrapper::before {
    background-color: #eafa72;
}
.cid-tpT24QrIYC .mbr-section-title {
    color: #101511;
    text-align: center;
}
.cid-tpT24QrIYC .mbr-card-title_1 {
    color: #101511;
}
.cid-tpT24QrIYC .mbr-card-title_2 {
    color: #101511;
}
.cid-tpT24QrIYC .mbr-card-title_3 {
    color: #ffffff;
}
.cid-tpT24QrIYC .mbr-text_1 {
    color: #6e716f;
}
.cid-tpT24QrIYC .mbr-text_2 {
    color: #6e716f;
}
.cid-tpT24QrIYC .mbr-text_3 {
    color: #e8e8e8;
}
.cid-tpT24QrIYC .mbr-number_1 {
    color: #101511;
}
.cid-tpT24QrIYC .mbr-number_2 {
    color: #101511;
}
.cid-tpT24QrIYC .mbr-number_3 {
    color: #ffffff;
}
.cid-tpT2cNLRfL {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #e8e8e8;
}
.cid-tpT2cNLRfL .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT2cNLRfL .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT2cNLRfL .card-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    padding: 0 10%;
}
@media (max-width: 992px) {
    .cid-tpT2cNLRfL .card-wrapper {
        padding: 0;
    }
}
@media (max-width: 768px) {
    .cid-tpT2cNLRfL .card-wrapper {
        display: block;
    }
}
.cid-tpT2cNLRfL .card-wrapper .card {
    max-width: 250px;
    max-height: 350px;
    padding: 0 32px 32px;
    border-radius: 40px;
    box-shadow: 6px 6px 20px #6e716f;
}
@media (max-width: 768px) {
    .cid-tpT2cNLRfL .card-wrapper .card {
        margin: 0 auto 32px;
    }
}
.cid-tpT2cNLRfL .card-wrapper .card .image {
    max-width: 100px;
    max-height: 160px;
    object-fit: cover;
    margin: -2rem auto 0;
}
.cid-tpT2cNLRfL .card-wrapper .card .mbr-section-title {
    margin: 1rem 0 0 0;
}
.cid-tpT2cNLRfL .card-wrapper .card .mbr-desc {
    margin: 0;
}
.cid-tpT2cNLRfL .card-wrapper .card .price-wrapper .mbr-price_1,
.cid-tpT2cNLRfL .card-wrapper .card .price-wrapper .mbr-price_2 {
    display: inline-flex;
    width: fit-content;
    padding: 5px 10px;
    text-align: center;
    border-radius: 40px;
    margin: 5px 0 0;
}
.cid-tpT2cNLRfL .card-wrapper .card .price-wrapper .mbr-price_1 {
    background-color: #ffffff;
}
.cid-tpT2cNLRfL .card-wrapper .card .price-wrapper .mbr-price_2 {
    background-color: #eafa72;
}
.cid-tpT2cNLRfL .card-wrapper .image-arrow {
    max-width: 107px;
    margin: 0 auto;
}
@media (max-width: 768px) {
    .cid-tpT2cNLRfL .card-wrapper .image-arrow {
        margin-bottom: 32px;
    }
}
.cid-tpT2cNLRfL .card_1 {
    background-color: #eafa72;
    transform: rotate(-5deg);
}
.cid-tpT2cNLRfL .card_2 {
    background-color: #e8e8e8;
    transform: rotate(2deg);
}
.cid-tpT2cNLRfL .mbr-section-title {
    color: #101511;
}
.cid-tpT2cNLRfL .mbr-desc {
    color: #6e716f;
}
.cid-tpT2cNLRfL .mbr-price_1 {
    color: #101511;
}
.cid-tpT2cNLRfL .mbr-price_2 {
    color: #101511;
}
.cid-tpT2aSrDcX {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #e8e8e8;
}
.cid-tpT2aSrDcX .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT2aSrDcX .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT2aSrDcX .row {
    justify-content: center;
}
.cid-tpT2aSrDcX .title-wrapper {
    margin-bottom: 75px;
}
@media (max-width: 992px) {
    .cid-tpT2aSrDcX .title-wrapper {
        margin-bottom: 45px;
    }
}
.cid-tpT2aSrDcX .title-wrapper .mbr-section-title {
    margin-bottom: 32px;
}
.cid-tpT2aSrDcX .title-wrapper .mbr-desc {
    margin: 0;
}
.cid-tpT2aSrDcX .card {
    margin-bottom: 32px;
}
@media (min-width: 992px) {
    .cid-tpT2aSrDcX .card {
        padding-left: 22px;
        padding-right: 22px;
    }
}
@media (max-width: 992px) {
    .cid-tpT2aSrDcX .card {
        margin-bottom: 24px;
    }
}
.cid-tpT2aSrDcX .card .card-wrapper {
    padding: 32px 45px;
    border-radius: 40px;
}
@media (max-width: 992px) {
    .cid-tpT2aSrDcX .card .card-wrapper {
        padding: 32px;
    }
}
.cid-tpT2aSrDcX .card .card-wrapper .mbr-card-title_1,
.cid-tpT2aSrDcX .card .card-wrapper .mbr-card-title_2,
.cid-tpT2aSrDcX .card .card-wrapper .mbr-card-title_3 {
    margin-bottom: 24px;
}
@media (max-width: 992px) {
    .cid-tpT2aSrDcX .card .card-wrapper .mbr-card-title_1,
    .cid-tpT2aSrDcX .card .card-wrapper .mbr-card-title_2,
    .cid-tpT2aSrDcX .card .card-wrapper .mbr-card-title_3 {
        margin-bottom: 16px;
    }
}
.cid-tpT2aSrDcX .card .card-wrapper .mbr-text_1,
.cid-tpT2aSrDcX .card .card-wrapper .mbr-text_2,
.cid-tpT2aSrDcX .card .card-wrapper .mbr-text_3 {
    margin: 0;
}
.cid-tpT2aSrDcX .card_1 .card-wrapper {
    background-color: #f2f2f2;
}
.cid-tpT2aSrDcX .card_2 .card-wrapper {
    background-color: #eafa72;
}
.cid-tpT2aSrDcX .card_3 .card-wrapper {
    background-color: #101511;
}
.cid-tpT2aSrDcX .mbr-section-title {
    color: #101511;
    text-align: center;
}
.cid-tpT2aSrDcX .mbr-desc {
    color: #6e716f;
    text-align: center;
}
.cid-tpT2aSrDcX .mbr-card-title_1 {
    color: #101511;
}
.cid-tpT2aSrDcX .mbr-card-title_2 {
    color: #101511;
}
.cid-tpT2aSrDcX .mbr-card-title_3 {
    color: #ffffff;
}
.cid-tpT2aSrDcX .mbr-text_1 {
    color: #6e716f;
}
.cid-tpT2aSrDcX .mbr-text_2 {
    color: #6e716f;
}
.cid-tpT2aSrDcX .mbr-text_3 {
    color: #e8e8e8;
}
.cid-tpT2eY0T8K {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #e8e8e8;
}
.cid-tpT2eY0T8K .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT2eY0T8K .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT2eY0T8K .card {
    display: flex;
    flex-direction: row;
}
@media (max-width: 992px) {
    .cid-tpT2eY0T8K .card {
        margin-bottom: 32px;
    }
}
.cid-tpT2eY0T8K .card img {
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 16px;
    box-shadow: 6px 6px 20px #6e716f;
}
.cid-tpT2eY0T8K .card .text-wrapper .mbr-desc_1,
.cid-tpT2eY0T8K .card .text-wrapper .mbr-desc_2 {
    margin-bottom: 16px;
}
.cid-tpT2eY0T8K .card .text-wrapper .mbr-section-title_1,
.cid-tpT2eY0T8K .card .text-wrapper .mbr-section-title_2 {
    margin: 0;
}
.cid-tpT2eY0T8K .card:nth-child(2) {
    justify-content: end;
}
.cid-tpT2eY0T8K .card:nth-child(2) img {
    margin: 0 0 0 16px;
}
.cid-tpT2eY0T8K .mbr-section-title_1 {
    color: #101511;
}
.cid-tpT2eY0T8K .mbr-section-title_2 {
    color: #101511;
    text-align: right;
}
.cid-tpT2eY0T8K .mbr-desc_1 {
    color: #6e716f;
}
.cid-tpT2eY0T8K .mbr-desc_2 {
    color: #6e716f;
    text-align: right;
}
.cid-tpT4cin415 {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: #e8e8e8;
}
.cid-tpT4cin415 .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT4cin415 .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT4cin415 .mbr-section-title {
    margin-bottom: 75px;
    color: #101511;
    text-align: center;
}
@media (max-width: 992px) {
    .cid-tpT4cin415 .mbr-section-title {
        margin-bottom: 45px;
    }
}
.cid-tpT4cin415 .embla__slide {
    display: flex;
    justify-content: center;
    position: relative;
    min-width: 520px;
    max-width: 520px;
}
@media (max-width: 768px) {
    .cid-tpT4cin415 .embla__slide {
        min-width: 70%;
        max-width: initial;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}
.cid-tpT4cin415 .embla__slide .card-wrap {
    padding: 0 64px 64px 64px;
    background-color: #f2f2f2;
    border-radius: 40px;
}
@media (max-width: 768px) {
    .cid-tpT4cin415 .embla__slide .card-wrap {
        padding: 0 32px 32px 32px;
    }
}
.cid-tpT4cin415 .embla__slide .card-wrap .item-wrapper {
    overflow: visible;
}
.cid-tpT4cin415 .embla__slide .card-wrap .item-wrapper .image-wrap img {
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 6px 6px 20px #6e716f;
}
@media (max-width: 992px) {
    .cid-tpT4cin415 .embla__slide .card-wrap .item-wrapper .image-wrap img {
        height: 250px;
    }
}
.cid-tpT4cin415 .embla__slide .card-wrap .content-wrap .mbr-card-title {
    margin: 48px 0 0 0;
}
@media (max-width: 768px) {
    .cid-tpT4cin415 .embla__slide .card-wrap .content-wrap .mbr-card-title {
        margin: 32px 0 0 0;
    }
}
.cid-tpT4cin415 .embla__slide .card-wrap .content-wrap .mbr-text {
    margin: 32px 0 0 0;
}
@media (max-width: 768px) {
    .cid-tpT4cin415 .embla__slide .card-wrap .content-wrap .mbr-text {
        margin: 16px 0 0 0;
    }
}
.cid-tpT4cin415 .embla__button--next,
.cid-tpT4cin415 .embla__button--prev {
    display: flex;
}
.cid-tpT4cin415 .embla__button {
    top: 50%;
    width: 60px;
    height: 60px;
    margin-top: -1.5rem;
    font-size: 22px;
    background-color: #101511;
    color: #eafa72;
    border: 2px solid #eafa72;
    border-radius: 50%;
    transition: all 0.3s;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}
@media (max-width: 768px) {
    .cid-tpT4cin415 .embla__button {
        display: none;
    }
}
.cid-tpT4cin415 .embla__button:disabled {
    cursor: default;
    display: none;
}
.cid-tpT4cin415 .embla__button:hover {
    opacity: .7;
}
.cid-tpT4cin415 .embla__button.embla__button--prev {
    left: 0;
    margin-left: 2.5rem;
}
.cid-tpT4cin415 .embla__button.embla__button--next {
    right: 0;
    margin-right: 2.5rem;
}
@media (max-width: 767px) {
    .cid-tpT4cin415 .embla__button {
        top: auto;
    }
}
.cid-tpT4cin415 .embla {
    position: relative;
    width: 100%;
}
.cid-tpT4cin415 .embla__viewport {
    overflow: hidden;
    width: 100%;
    padding-top: 2rem;
}
.cid-tpT4cin415 .embla__viewport.is-draggable {
    cursor: grab;
}
.cid-tpT4cin415 .embla__viewport.is-dragging {
    cursor: grabbing;
}
.cid-tpT4cin415 .embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.cid-tpT4cin415 .item-menu-overlay {
    opacity: 0 !important;
}
.cid-tpT4cin415 .mbr-card-title {
    color: #101511;
}
.cid-tpT4cin415 .mbr-text {
    color: #6e716f;
}
.cid-tpT3uiPq7J {
    padding-top: 5rem;
    padding-bottom: 7rem;
    background-color: #e8e8e8;
}
.cid-tpT3uiPq7J .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT3uiPq7J .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT3uiPq7J .row.card {
    padding: 0 90px;
    background-color: #101511;
    border-radius: 60px;
    flex-direction: row;
    height: auto;
}
@media (max-width: 768px) {
    .cid-tpT3uiPq7J .row.card {
        padding: 0 32px;
        margin: 0;
    }
}
.cid-tpT3uiPq7J .row.card .subtitle-wrapper {
    margin: -25px 0 0 0;
    transform: rotate(-5deg);
    background-color: #ffffff;
    border-radius: 17px;
    padding: 20px;
}
.cid-tpT3uiPq7J .row.card .subtitle-wrapper .mbr-section-subtitle {
    margin: 0;
}
.cid-tpT3uiPq7J .row.card .mbr-section-title {
    margin: 60px 0 0 0;
}
@media (max-width: 992px) {
    .cid-tpT3uiPq7J .row.card .mbr-section-title {
        margin: 32px 0 0 0;
    }
}
.cid-tpT3uiPq7J .row.card .image-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 96px 0 -4rem 0;
}
@media (max-width: 992px) {
    .cid-tpT3uiPq7J .row.card .image-wrapper {
        margin: 32px 0 0 0;
    }
}
@media (max-width: 768px) {
    .cid-tpT3uiPq7J .row.card .image-wrapper {
        display: block;
    }
}
.cid-tpT3uiPq7J .row.card .image-wrapper img {
    object-fit: cover;
    border-radius: 10px;
}
.cid-tpT3uiPq7J .row.card .image-wrapper img:first-child {
    width: 220px;
    height: 296px;
    transform: rotate(330deg);
}
@media (max-width: 768px) {
    .cid-tpT3uiPq7J .row.card .image-wrapper img:first-child {
        transform: rotate(-5deg);
        margin: 0 auto 32px;
        width: 180px;
    }
}
.cid-tpT3uiPq7J .row.card .image-wrapper img:last-child {
    width: 260px;
    height: 350px;
    transform: rotate(31deg);
}
@media (max-width: 768px) {
    .cid-tpT3uiPq7J .row.card .image-wrapper img:last-child {
        transform: rotate(5deg);
        margin: 0 auto;
        width: 180px;
    }
}
.cid-tpT3uiPq7J .row.card .text-wrapper {
    position: relative;
    z-index: 1;
    padding: 105px 0 105px 0;
    height: 100%;
}
@media (max-width: 768px) {
    .cid-tpT3uiPq7J .row.card .text-wrapper {
        padding-top: 32px;
    }
}
.cid-tpT3uiPq7J .row.card .text-wrapper:hover::before {
    right: 40%;
    top: 40%;
}
.cid-tpT3uiPq7J .row.card .text-wrapper::before {
    content: '';
    position: absolute;
    right: 20%;
    top: 35%;
    border-radius: 100%;
    width: 200px;
    height: 200px;
    filter: blur(75px) blur(75px);
    background-color: #ffffff;
    transition: all .3s ease;
}
.cid-tpT3uiPq7J .row.card .text-wrapper .mbr-text {
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
}
.cid-tpT3uiPq7J .row.card .text-wrapper .mbr-section-btn {
    position: relative;
    z-index: 1;
}
.cid-tpT3uiPq7J .mbr-section-title {
    color: #ffffff;
}
.cid-tpT3uiPq7J .mbr-section-subtitle {
    color: #101511;
    text-align: center;
}
.cid-tpT3uiPq7J .mbr-text {
    color: #6e716f;
}
.cid-tpT3z3ZR5a {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #101511;
}
.cid-tpT3z3ZR5a .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT3z3ZR5a .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT3z3ZR5a .title-wrapper {
    margin-bottom: 75px;
}
@media (max-width: 992px) {
    .cid-tpT3z3ZR5a .title-wrapper {
        margin-bottom: 45px;
    }
}
.cid-tpT3z3ZR5a .title-wrapper .mbr-section-title {
    margin-bottom: 32px;
}
.cid-tpT3z3ZR5a .title-wrapper .mbr-desc {
    margin-bottom: 0;
}
.cid-tpT3z3ZR5a .app-video-wrapper > img {
    border-radius: 60px;
}
.cid-tpT3z3ZR5a iframe {
    border-radius: 60px;
}
.cid-tpT3z3ZR5a .mbr-section-title {
    color: #ffffff;
    text-align: center;
}
.cid-tpT3z3ZR5a .mbr-desc {
    color: #e8e8e8;
    text-align: center;
}
.cid-tpT3KrVnBC {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #101511;
}
.cid-tpT3KrVnBC .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT3KrVnBC .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT3KrVnBC .icon-wrapper .mbr-iconfont {
    font-size: 38px;
}
@media (max-width: 992px) {
    .cid-tpT3KrVnBC .icon-wrapper .mbr-iconfont {
        margin-bottom: 32px;
    }
}
@media (max-width: 992px) {
    .cid-tpT3KrVnBC .text-wrapper {
        margin-bottom: 32px;
    }
}
.cid-tpT3KrVnBC .text-wrapper .mbr-section-title {
    margin-bottom: 32px;
}
.cid-tpT3KrVnBC .text-wrapper .mbr-text {
    margin-bottom: 0;
}
.cid-tpT3KrVnBC .image-wrapper img {
    width: 100%;
    max-height: 340px;
    object-fit: cover;
    box-shadow: 6px 6px 20px #6e716f;
    border-radius: 10px;
}
.cid-tpT3KrVnBC .mbr-section-title,
.cid-tpT3KrVnBC .mbr-iconfont {
    color: #101511;
}
.cid-tpT3KrVnBC .mbr-text {
    color: #f2f2f2;
    text-align: left;
}
.cid-tpT3KrVnBC .mbr-section-title,
.cid-tpT3KrVnBC .mbr-iconfont,
.cid-tpT3KrVnBC .icon-wrapper {
    color: #ffffff;
}
.cid-tpT3wxRXyR {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #101511;
}
.cid-tpT3wxRXyR .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT3wxRXyR .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT3wxRXyR .row {
    justify-content: center;
}
.cid-tpT3wxRXyR .title-wrapper {
    margin-bottom: 75px;
}
@media (max-width: 992px) {
    .cid-tpT3wxRXyR .title-wrapper {
        margin-bottom: 45px;
    }
}
.cid-tpT3wxRXyR .title-wrapper .mbr-section-title {
    margin: 0;
}
.cid-tpT3wxRXyR .card-wrapper {
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 40px;
    min-width: 216px;
}
@media (max-width: 992px) {
    .cid-tpT3wxRXyR .card-wrapper {
        margin: 0 0 24px 0 !important;
        transform: none !important;
    }
}
.cid-tpT3wxRXyR .mbr-text_1,
.cid-tpT3wxRXyR .mbr-text_2,
.cid-tpT3wxRXyR .mbr-text_3 {
    margin-bottom: 0;
}
.cid-tpT3wxRXyR .cards_1 .card-wrapper:first-child {
    transform: rotate(350deg);
    background-color: #6e716f;
    opacity: .9;
    position: relative;
    z-index: 2;
}
.cid-tpT3wxRXyR .cards_1 .card-wrapper:nth-child(2) {
    transform: rotate(340deg);
    background-color: #eafa72;
    margin: -35px -3rem 20px 12rem;
    position: relative;
    z-index: 1;
}
.cid-tpT3wxRXyR .cards_1 .card-wrapper:last-child {
    transform: rotate(5deg);
    background-color: #e8e8e8;
}
.cid-tpT3wxRXyR .cards_2 .card-wrapper:first-child {
    transform: rotate(5deg);
    background-color: #e8e8e8;
}
.cid-tpT3wxRXyR .cards_2 .card-wrapper:nth-child(2) {
    transform: rotate(353deg);
    background-color: #6e716f;
    margin: 24px 10rem 0px -3rem;
    opacity: .9;
    position: relative;
    z-index: 2;
}
.cid-tpT3wxRXyR .cards_2 .card-wrapper:last-child {
    transform: rotate(0);
    background-color: #eafa72;
    margin: 0 -3rem 0 6rem;
    position: relative;
    z-index: 1;
}
.cid-tpT3wxRXyR .cards_3 .card-wrapper:first-child {
    transform: rotate(15deg);
    background-color: #eafa72;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
}
.cid-tpT3wxRXyR .cards_3 .card-wrapper:nth-child(2) {
    transform: rotate(353deg);
    background-color: #6e716f;
    margin: 0 10rem 0 -3rem;
    opacity: .9;
    position: relative;
    z-index: 2;
}
.cid-tpT3wxRXyR .cards_3 .card-wrapper:last-child {
    transform: rotate(5deg);
    background-color: #e8e8e8;
}
.cid-tpT3wxRXyR .mbr-section-title {
    color: #ffffff;
    text-align: center;
}
.cid-tpT3wxRXyR .mbr-text_1 {
    color: #e8e8e8;
    text-align: center;
}
.cid-tpT3wxRXyR .mbr-text_2 {
    color: #101511;
    text-align: center;
}
.cid-tpT3wxRXyR .mbr-text_3 {
    color: #101511;
    text-align: center;
}
.cid-tpT3Dc7xXH {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #101511;
}
.cid-tpT3Dc7xXH .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT3Dc7xXH .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT3Dc7xXH .row {
    justify-content: center;
}
.cid-tpT3Dc7xXH .title-wrapper {
    margin-bottom: 75px;
}
@media (max-width: 992px) {
    .cid-tpT3Dc7xXH .title-wrapper {
        margin-bottom: 45px;
    }
}
.cid-tpT3Dc7xXH .title-wrapper .mbr-section-title {
    margin-bottom: 0;
}
.cid-tpT3Dc7xXH .tabs-wrapper {
    background-color: #f2f2f2;
    border-radius: 40px;
    padding: 64px;
}
@media (max-width: 768px) {
    .cid-tpT3Dc7xXH .tabs-wrapper {
        padding: 32px;
    }
}
.cid-tpT3Dc7xXH .tabs-wrapper .nav.nav-tabs {
    border: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 64px;
}
@media (max-width: 768px) {
    .cid-tpT3Dc7xXH .tabs-wrapper .nav.nav-tabs {
        margin-bottom: 32px;
    }
}
.cid-tpT3Dc7xXH .tabs-wrapper .nav.nav-tabs .nav-item {
    margin: 0 10px 10px 0;
}
.cid-tpT3Dc7xXH .tabs-wrapper .nav.nav-tabs .nav-item .nav-link {
    background-color: #101511;
    color: #eafa72;
    border: none !important;
    padding: 10px 20px;
    border-radius: 100px;
    transition: all .3s ease;
}
.cid-tpT3Dc7xXH .tabs-wrapper .nav.nav-tabs .nav-item .nav-link.active {
    background-color: #eafa72;
    color: #101511;
}
.cid-tpT3Dc7xXH .tabs-wrapper .tab-content .tab-pane .mbr-text {
    margin-bottom: 32px;
}
.cid-tpT3Dc7xXH .mbr-section-title {
    color: #ffffff;
    text-align: center;
}
.cid-tpT3Dc7xXH .mbr-text {
    color: #6e716f;
}
.cid-tpT3ExF8iC {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #e8e8e8;
}
.cid-tpT3ExF8iC .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT3ExF8iC .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT3ExF8iC .row {
    justify-content: center;
}
.cid-tpT3ExF8iC .title-wrapper {
    margin-bottom: 75px;
}
@media (max-width: 992px) {
    .cid-tpT3ExF8iC .title-wrapper {
        margin-bottom: 45px;
    }
}
.cid-tpT3ExF8iC .title-wrapper .mbr-section-title {
    margin-bottom: 0;
}
@media (min-width: 992px) {
    .cid-tpT3ExF8iC .card {
        padding-left: 22px;
        padding-right: 22px;
    }
}
@media (max-width: 992px) {
    .cid-tpT3ExF8iC .card {
        margin-bottom: 24px;
    }
}
.cid-tpT3ExF8iC .card .card-wrapper {
    padding: 0 64px 64px 64px;
    border-radius: 40px;
    overflow: visible;
    background-color: #f2f2f2;
}
@media (max-width: 992px) {
    .cid-tpT3ExF8iC .card .card-wrapper {
        padding: 32px;
    }
}
.cid-tpT3ExF8iC .card .card-wrapper .icon-wrapper {
    margin: -1rem 0 0 0;
}
.cid-tpT3ExF8iC .card .card-wrapper .icon-wrapper .mbr-iconfont {
    font-size: 48px;
    display: inline-block;
    transform: rotate(5deg);
}
.cid-tpT3ExF8iC .card .card-wrapper .mbr-price {
    margin: 64px 0 0 0;
}
@media (max-width: 992px) {
    .cid-tpT3ExF8iC .card .card-wrapper .mbr-price {
        margin: 64px 0 0 0;
    }
}
.cid-tpT3ExF8iC .card .card-wrapper .mbr-desc {
    margin: 32px 0 0 0;
}
.cid-tpT3ExF8iC .card .card-wrapper .mbr-section-btn {
    margin-top: 32px;
}
.cid-tpT3ExF8iC .card .card-wrapper .mbr-section-btn .btn {
    width: 100%;
    max-width: 280px;
    min-width: auto;
}
@media (max-width: 992px) {
    .cid-tpT3ExF8iC .card .card-wrapper .mbr-section-btn .btn {
        min-width: 140px;
        width: auto;
    }
}
.cid-tpT3ExF8iC .mbr-section-title {
    color: #101511;
    text-align: center;
}
.cid-tpT3ExF8iC .mbr-price,
.cid-tpT3ExF8iC .mbr-iconfont {
    color: #6e716f;
}
.cid-tpT3ExF8iC .mbr-price,
.cid-tpT3ExF8iC .icon-wrapper,
.cid-tpT3ExF8iC .mbr-section-btn {
    text-align: center;
}
.cid-tpT3ExF8iC .mbr-desc {
    color: #101511;
    text-align: center;
}
.cid-tpT3Mznzkc {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #e8e8e8;
}
.cid-tpT3Mznzkc .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT3Mznzkc .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT3Mznzkc .row {
    justify-content: center;
}
.cid-tpT3Mznzkc .title-wrapper {
    margin-bottom: 106px;
}
@media (max-width: 992px) {
    .cid-tpT3Mznzkc .title-wrapper {
        margin-bottom: 52px;
    }
}
.cid-tpT3Mznzkc .title-wrapper .mbr-section-title {
    margin: 0;
}
.cid-tpT3Mznzkc .card-wrapper {
    padding: 0 90px 90px;
    background-color: #f2f2f2;
    border-radius: 40px;
    overflow: visible;
}
@media (max-width: 992px) {
    .cid-tpT3Mznzkc .card-wrapper {
        padding: 0 32px 32px;
    }
}
.cid-tpT3Mznzkc .card-wrapper .title-card-wrapper {
    position: relative;
}
.cid-tpT3Mznzkc .card-wrapper .title-card-wrapper::before {
    content: '';
    position: absolute;
    left: 40%;
    top: -3rem;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    filter: blur(5px) blur(5px);
    background-color: #ffffff;
}
.cid-tpT3Mznzkc .card-wrapper .title-card-wrapper .mbr-card-title {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 71px;
    height: 71px;
    border-radius: 50%;
    margin: -3rem 0 0 0;
    background-color: #eafa72;
}
.cid-tpT3Mznzkc .card-wrapper .mbr-text {
    margin: 56px 0 0 0;
}
@media (max-width: 992px) {
    .cid-tpT3Mznzkc .card-wrapper .mbr-text {
        margin: 32px 0 0 0;
    }
}
.cid-tpT3Mznzkc .card-wrapper .mbr-desc {
    margin: 32px 0 0 0;
}
.cid-tpT3Mznzkc .mbr-section-title {
    color: #101511;
    text-align: center;
}
.cid-tpT3Mznzkc .mbr-card-title {
    color: #101511;
}
.cid-tpT3Mznzkc .mbr-text {
    color: #6e716f;
    text-align: center;
}
.cid-tpT3Mznzkc .mbr-desc {
    color: #101511;
    text-align: center;
}
.cid-tpT3Mznzkc .mbr-card-title,
.cid-tpT3Mznzkc .title-card-wrapper {
    text-align: center;
}
.cid-tpT4m6r0VK {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #e8e8e8;
}
.cid-tpT4m6r0VK .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT4m6r0VK .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT4m6r0VK .row {
    justify-content: center;
}
.cid-tpT4m6r0VK .title-wrapper {
    margin-bottom: 75px;
}
@media (max-width: 992px) {
    .cid-tpT4m6r0VK .title-wrapper {
        margin-bottom: 45px;
    }
}
.cid-tpT4m6r0VK .title-wrapper .mbr-section-title {
    margin-bottom: 32px;
}
.cid-tpT4m6r0VK .title-wrapper .mbr-text {
    margin-bottom: 0;
}
.cid-tpT4m6r0VK .item {
    margin-bottom: 32px;
}
.cid-tpT4m6r0VK .item:nth-child(2n) .item-wrapper .item-img {
    transform: rotate(-5deg);
}
.cid-tpT4m6r0VK .item .item-wrapper {
    overflow: visible;
}
.cid-tpT4m6r0VK .item .item-wrapper .item-img {
    transform: rotate(5deg);
}
.cid-tpT4m6r0VK .item .item-wrapper .item-img img {
    max-height: 500px;
    min-height: 500px;
    object-fit: cover;
    border-radius: 40px;
    box-shadow: 6px 6px 20px #6e716f;
}
@media (max-width: 992px) {
    .cid-tpT4m6r0VK .item .item-wrapper .item-img img {
        min-height: 250px;
        max-height: 250px;
    }
}
.cid-tpT4m6r0VK .mbr-section-title {
    color: #101511;
    text-align: center;
}
.cid-tpT4m6r0VK .mbr-text {
    color: #6e716f;
    text-align: center;
}
.cid-tpT2gCGI9U {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #101511;
}
.cid-tpT2gCGI9U .mbr-fallback-image.disabled {
    display: none;
}
.cid-tpT2gCGI9U .mbr-fallback-image {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.cid-tpT2gCGI9U .mbr-section-title {
    margin-bottom: 24px;
    color: #ffffff;
}
@media (max-width: 768px) {
    .cid-tpT2gCGI9U .mbr-section-title {
        text-align: center;
    }
}
.cid-tpT2gCGI9U .soc-wrapper {
    display: flex;
    flex-wrap: wrap;
}
@media (max-width: 768px) {
    .cid-tpT2gCGI9U .soc-wrapper {
        margin-bottom: 22px;
        display: block;
    }
}
.cid-tpT2gCGI9U .soc-wrapper .mbr-soc {
    margin-bottom: 16px;
    margin-right: 30px;
    transition: all .3s ease;
}
.cid-tpT2gCGI9U .soc-wrapper .mbr-soc:hover {
    opacity: .7;
}
@media (max-width: 768px) {
    .cid-tpT2gCGI9U .soc-wrapper .mbr-soc {
        margin-right: 0;
        text-align: center;
    }
}
.cid-tpT2gCGI9U .soc-wrapper .mbr-soc .mbr-iconfont {
    margin-right: 15px;
    font-size: 20px;
    width: 31px;
    height: 31px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #6e716f;
    border-radius: 100%;
}
.cid-tpT2gCGI9U .list {
    list-style-type: none;
    padding: 1rem 0 0 0;
    margin: 0;
    color: #e8e8e8;
    text-align: right;
}
@media (max-width: 768px) {
    .cid-tpT2gCGI9U .list {
        text-align: center;
    }
}
.cid-tpT2gCGI9U .list li {
    margin-bottom: 24px;
    transition: all .3s ease;
}
.cid-tpT2gCGI9U .list li:hover {
    color: #eafa72;
}
.cid-tpT2gCGI9U .copyright {
    margin: 32px 0 0 0;
    color: #6e716f;
    text-align: center;
}
@media (max-width: 768px) {
    .cid-tpT2gCGI9U .copyright {
        text-align: center;
    }
}
.cid-tpT2gCGI9U .mbr-soc,
.cid-tpT2gCGI9U .mbr-iconfont {
    color: #e8e8e8;
    text-align: left;
}

.accordion__panel {
    background-color: #000000;
    text-align: left;
}
p.FAQ.ans {
    color: white;
}

button.btn-extra>span,
a.button.btn-extra>span,
input[type="button"].button.btn-extra>span {
    display: table-cell;
    vertical-align: middle;
    padding: 0 20px 0 35px;
    text-align: left
}

button.btn-extra:hover,
a.button.btn-extra:hover,
input[type="button"].button.btn-extra:hover {
    color: black;
    background: #ffffff;
    border: none;
    border-right: 1px solid transparent !important
}

a.button-btn-extra{
    border-radius: 50px;
    padding-top: 10px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-left: 40px;
    font-family: inherit;
    font-weight: inherit;
    border: 2px black solid;
    display: flex;
    background-color: #1c9d1c;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all ease-in-out 300ms;
    flex-direction: row;
    text-decoration: none;

}
a.button-btn-extra:hover{
    color: white;
}


/*Contact us*/
section.section-Contact {
    background:  url("../img/banner2.jpg") no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: auto;
    height: 500px;
}
section.section-Contact::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(20 20 20 / 46%);
}

.container.container-contact-form{
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    background-color: #fafafa;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /*z-index: -1;*/
}

.form {
    width: 100%;
    max-width: 820px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.contact-form {
    background-color: #209261;
    position: relative;
}

.circle {
    border-radius: 50%;
    background: linear-gradient(135deg, #52d686 20%, #21874a);
    position: absolute;
}

.circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px;
}

.circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px;
}

.contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #209261;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
}

form {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative;
}

.title {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
}

.input-container {
    position: relative;
    margin: 1rem 0;
}

.input {
    width: 100%;
    outline: none;
    border: 2px solid #fafafa;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 5px;
    transition: 0.3s;
}

textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 350px;
    border-radius: 5px;
    resize: none;
    overflow-y: auto;
}

.input-container label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s;
}

.input-container.textarea label {
    top: 1rem;
    transform: translateY(0);
}

input.contact-sub-btn {
    padding: 0.6rem 1.3rem;
    background-color: #fff;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    color: #209261;
    line-height: 1;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
    width: 100%;
}

input.contact-sub-btn:hover {
    background-color: transparent;
    color: #fff;
}

.input-container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
}

.input-container span:before,
.input-container span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #1abc9c;
    top: 50%;
    transform: translateY(-50%);
}

.input-container span:before {
    left: 50%;
}

.input-container span:after {
    right: 50%;
}

.input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
    width: 50%;
    opacity: 1;
}

.contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
}

.contact-info .title {
    color: #208649;
}

.text {
    color: #333;
    margin: 1.5rem 0 2rem 0;
    text-align: left;
}

.information {
    display: flex;
    color: #555;
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem;
}

.information i {
    color: #258e4f;
}

.icon {
    width: 28px;
    margin-right: 0.7rem;
}

/*.social-media {*/
/*    padding: 2rem 0 0 0;*/
/*}*/

.social-media p {
    color: #333;
}

.social-icons {
    display: flex;
    margin-top: 0.5rem;
}

.social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(45deg, #279152, #149279);
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
}

.social-icons a:hover {
    transform: scale(1.05);
}

.contact-info:before {
    content: "";
    position: absolute;
    width: 110px;
    height: 100px;
    border: 22px solid #35a863;
    border-radius: 50%;
    bottom: -45px;
    right: 50px;
    opacity: 0.3;
}

.big-circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #1d8145, #58e18e);
    bottom: 50%;
    right: 50%;
    transform: translate(-40%, 38%);
}

.big-circle:after {
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: #fafafa;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px);
}

.square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(181%, 11%);
    opacity: 0.2;
}

@media (max-width: 850px) {
    .form {
        grid-template-columns: 1fr;
    }

    .contact-info:before {
        bottom: initial;
        top: -75px;
        right: 65px;
        transform: scale(0.95);
    }

    .contact-form:before {
        top: -13px;
        left: initial;
        right: 70px;
    }

    .square {
        transform: translate(140%, 43%);
        height: 350px;
    }

    .big-circle {
        bottom: 75%;
        transform: scale(0.9) translate(-40%, 30%);
        right: 50%;
    }

    .text {
        margin: 1rem 0 1.5rem 0;
        text-align: left;
    }

    .social-media {
        padding: 1.5rem 0 0 0;
    }
}

@media (max-width: 480px) {
    .container.container-contact-form {
        padding: 1.5rem;
    }

    .contact-info:before {
        display: none;
    }

    .square,
    .big-circle {
        display: none;
    }

    form,
    .contact-info {
        padding: 1.7rem 1.6rem;
    }

    .text,
    .information,
    .social-media p {
        font-size: 0.8rem;
    }

    .title {
        font-size: 1.15rem;
    }

    .social-icons a {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .icon {
        width: 23px;
    }

    .input {
        padding: 0.45rem 1.2rem;
    }

    input.contact-sub-btn {
        padding: 0.45rem 1.2rem;
    }
}

.contact-info h3.title{
    text-align: justify;
}
h5.adrs-txt{
    color: #208649;
    text-align: left;
}





/*!*Pricing view page css*!*/
h2.price-head{
    margin-top: 3rem;
}
.panel {

    transition: padding 0.5s;
    -moz-transition: padding 0.5s; /* Firefox 4 */
    -webkit-transition: padding 0.5s; /* Safari and Chrome */
    -o-transition: padding 0.5s; /* Opera */
    -ms-transition:padding 0.5s; /* IE9 (maybe) */

}
.panel:hover {
    padding:10px;
}

.panel.panel-danger {
    background-color: #bdbdde;
    border-radius: 17px;
    box-shadow: 0px 0px 14px #5c4d60;
     height: 10rem;
    /*padding: 30px 0px;*/
}
.btn-success {
    color: #fff;
    background-color: #4e4b4b;
    text-decoration: none;
    padding: 10px 20px;
    font-size: medium;
    border-radius: 7px;
    box-shadow: 0px 0px 14px #5c4d60;
}



.demo {
    padding: 30px 0
}

.pricingTable {
    text-align: center
}

h4 {
    color: #444
}

.pricingTable {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: all .3s ease 0s;
    border: 1px solid #000000;

}

.pricingTable:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, .8) inset, 0 0 10px rgba(0, 0, 0, .8);
    fill: #ae003d;
}
path:hover{
    fill:#ae003d;
}


.pricingTable svg {
    display: block;
    margin-left: -1px
}

.pricingTable .pricing-content {
    padding: 50px 0 30px;
    position: relative
}

.pricingTable .title {
    font-size: 35px;
    font-weight: 600;
    color: #ae003d;
    text-transform: uppercase;
    margin: 0 0 10px
}

.pricingTable .pricing-content ul {
    padding: 0;
    margin: 0 0 30px;
    list-style: none;
    text-align: center;
}
.pricingTable .pricing-content ul li {
    font-size: 18px;
    color: rgb(0, 0, 0);
    line-height: 40px;
    text-transform: capitalize
}

.pricingTable .pricingTable-signup {
    display: inline-block;
    padding: 8px 20px;
    background: #000000;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    transition: all .3s ease 0s;
    text-decoration: none;
    margin-top: 30px;
}


.pricingTable .pricingTable-signup:hover {
    color: #ffffff;
    background: #ae003d;
    box-shadow: 0 0 10px #ae003d, 0 0 10px #00000003 inset;
    text-decoration: none;
}

.pricingTable.blue .title {
    color:#ae003d
}

.pricingTable.blue .pricingTable-signup {
    background: #000000
}

.pricingTable.blue .pricingTable-signup:hover {
    color: #ffffff;
    background: #ae003d;
    box-shadow: 0 0 10px #ae003d, 0 0 10px #00000003 inset;
    text-decoration: none;
}

.pricingTable.red .title {
    color:#ae003d
}

.pricingTable.red .pricingTable-signup {
    background: #000000
}

.pricingTable.red .pricingTable-signup:hover {
    color: #ffffff;
    background: #ae003d;
    box-shadow: 0 0 10px #ae003d, 0 0 10px #00000003 inset;
    text-decoration: none;
}

@media only screen and (max-width:990px) {
    .pricingTable {
        margin-bottom: 30px
    }
}


/*Partners*/
.g-6,.gx-6{--bs-gutter-x: 4.5rem}.g-6,.gy-6{--bs-gutter-y: 4.5rem}@media(min-width: 576px){.g-sm-6,.gx-sm-6{--bs-gutter-x: 4.5rem}.g-sm-6,.gy-sm-6{--bs-gutter-y: 4.5rem}}@media(min-width: 768px){.g-md-6,.gx-md-6{--bs-gutter-x: 4.5rem}.g-md-6,.gy-md-6{--bs-gutter-y: 4.5rem}}@media(min-width: 992px){.g-lg-6,.gx-lg-6{--bs-gutter-x: 4.5rem}.g-lg-6,.gy-lg-6{--bs-gutter-y: 4.5rem}}@media(min-width: 1200px){.g-xl-6,.gx-xl-6{--bs-gutter-x: 4.5rem}.g-xl-6,.gy-xl-6{--bs-gutter-y: 4.5rem}}@media(min-width: 1400px){.g-xxl-6,.gx-xxl-6{--bs-gutter-x: 4.5rem}.g-xxl-6,.gy-xxl-6{--bs-gutter-y: 4.5rem}}.mb-6{margin-bottom:4.5rem !important}.py-6{padding-top:4.5rem !important;padding-bottom:4.5rem !important}@media(min-width: 576px){.mb-sm-6{margin-bottom:4.5rem !important}.py-sm-6{padding-top:4.5rem !important;padding-bottom:4.5rem !important}}@media(min-width: 768px){.mb-md-6{margin-bottom:4.5rem !important}.py-md-6{padding-top:4.5rem !important;padding-bottom:4.5rem !important}}@media(min-width: 992px){.mb-lg-6{margin-bottom:4.5rem !important}.py-lg-6{padding-top:4.5rem !important;padding-bottom:4.5rem !important}}@media(min-width: 1200px){.mb-xl-6{margin-bottom:4.5rem !important}.py-xl-6{padding-top:4.5rem !important;padding-bottom:4.5rem !important}}@media(min-width: 1400px){.mb-xxl-6{margin-bottom:4.5rem !important}.py-xxl-6{padding-top:4.5rem !important;padding-bottom:4.5rem !important}}

/*blog*/
/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
}

.blog .entry-front {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.blog .entry-front .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}

.blog .entry-front .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
}

.blog .entry-front .entry-title a {
    color: #000000;
    transition: 0.3s;
    text-decoration: none;
}

.blog .entry-front .entry-title a:hover {
    color: #d9232d;
}

.blog .entry-front .entry-meta {
    margin-bottom: 15px;
    color: #c1c8d0;
}

.blog .entry-front .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.blog .entry-front .entry-meta ul li+li {
    padding-left: 20px;
}

.blog .entry-front .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
    color: #585858;
}

.blog .entry-front .entry-meta a {
    color: #000000;
    font-size: 15px;
    display: inline-block;
    line-height: 1;
    text-decoration: none;
}

.blog .entry-front .entry-content p {
    line-height: 24px;
    text-align: left;
}

.blog .entry-front .entry-content .read-more {
    /*-moz-text-align-last: right;*/
    /*text-align-last: right;*/
    float: inline-end;
}

.blog .entry-front .entry-content .read-more a {
    display: inline-block;
    background:#1c9d1c;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
    text-decoration: none;
}

.blog .entry-front .entry-content .read-more a:hover {
    background: #1c9d1c;
}

.blog .entry-front .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
}

.blog .entry-front .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.blog .entry-front .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}

.blog .entry-front .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #556270;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog .entry-front .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}

.blog .entry-front .entry-footer i {
    color: #a4afba;
    display: inline;
}

.blog .entry-front .entry-footer a {
    color: #606f7e;
    transition: 0.3s;
}

.blog .entry-front .entry-footer a:hover {
    color: #d9232d;
}

.blog .entry-front .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}

.blog .entry-front .entry-footer .cats li {
    display: inline-block;
}

.blog .entry-front .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}

.blog .entry-front .entry-footer .tags li {
    display: inline-block;
}

.blog .entry-front .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
}

.blog .entry-front .entry-footer .share {
    font-size: 16px;
}

.blog .entry-front .entry-footer .share i {
    padding-left: 5px;
}

.blog .entry-single {
    margin-bottom: 30px;
}

.blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
    width: 120px;
    margin-right: 20px;
}

.blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #556270;
}

.blog .blog-author .social-links {
    margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
    color: rgba(85, 98, 112, 0.5);
    margin-right: 5px;
}

.blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
}

.blog .blog-comments {
    margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
    font-weight: bold;
}

.blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
}

.blog .blog-comments .comment .comment-img {
    margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
    width: 60px;
}


.cd__main{

    min-height: 720px;
}
.conbg {
    width: 100%;
    height: 500px;
}
.bg2 {
    /*z-index: 2;*/
    position: relative;
    width: 70%;
    margin: 100px auto;
    transform: rotate(10deg);
    background-image: url("../img/blog-3.jpeg");
    background-size: cover;
    background-position: right -50px;
    background-color: lightslategray;
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.5);

}
.bg2::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(52 52 52 / 80%);
}

.bg1 {
    z-index: 3;
    position: relative;
    margin: auto;
    transform: rotate(-5deg);
    background-image:url("../img/blog-3.jpeg");
    background-size: cover;
    background-position: right -70px;
    background-color: lightslategray;
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.5);

}
.bg1::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(52 52 52 / 80%);
}

.bg {
    z-index: 4;
    position: relative;
    margin: auto;
    transform: rotate(-5deg);
    height: 500px;
    background-image: url("../img/blog-3.jpeg");
    background-size: cover;
    background-position: right -50px;
    background-color: lightslategray;
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.5);
    /*filter: brightness(0.9);*/
}
.bg::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(52 52 52 / 80%);
}

.bg-text {
    width: 100%;
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
}
.bg-text a {
    margin: 5px;
    float: right;
}

#bg1:target .bg {
    -webkit-animation: ciut 1s forwards;
    animation: ciut 1s forwards;
}
#bg1:target {
    -webkit-animation: fre 0.3s forwards;
    animation: fre 0.3s forwards;
}
#bg2:target .bg1 {
    -webkit-animation: ciut 1s forwards;
    animation: ciut 1s forwards;
}
#bg2:target {
    -webkit-animation: fre1 0.3s forwards;
    animation: fre1 0.3s forwards;
}
#conbg:target .bg2 {
    -webkit-animation: ciut1 1s;
    animation: ciut1 1s;
}
@-webkit-keyframes fre1 {
    0% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes fre1 {
    0% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@-webkit-keyframes fre {
    0% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(-10deg);
    }
}
@keyframes fre {
    0% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(-10deg);
    }
}
@-webkit-keyframes ciut {
    0% {
        opacity: 1;
        transform: rotate(-5deg) scale(1);
    }
    100% {
        opacity: 0;
        transform: rotate(360deg) scale(0);
    }
}
@keyframes ciut {
    0% {
        opacity: 1;
        transform: rotate(-5deg) scale(1);
    }
    100% {
        opacity: 0;
        transform: rotate(360deg) scale(0);
    }
}
@-webkit-keyframes ciut1 {
    0% {
        opacity: 0;
        transform: rotate(0deg) scale(0);
    }
    100% {
        opacity: 1;
        transform: rotate(370deg) scale(1);
    }
}
@keyframes ciut1 {
    0% {
        opacity: 0;
        transform: rotate(0deg) scale(0);
    }
    100% {
        opacity: 1;
        transform: rotate(370deg) scale(1);
    }
}

p.blog-Pg {
    /*line-height: 13px;*/
    color: white;
    text-align: left;
}
path.blog-path:hover{
    fill: #1c9d1c;
}
.facebook-popup{
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 2;
    font-size: 4rem;
}
path.facebook-icon:hover{
    fill: #0090fe !important;
}
